<template>
  <div class="logout" role="main">
    <b-container>
      <b-row class="login-wrapper">
        <button class="loginButton" v-if="!$msal.isAuthenticated()" v-on:click="$msal.signIn()">Sign In</button>
      </b-row>
    </b-container>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logoutMsg {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

.login-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}

.loginButton {
  font-size: 16px;
  font-weight: 600;
  background-color: #004EA8;
  color: white;
  border-radius: 20px;
  border: none;
  padding: 4px 14px;
  transition: 0.15s;
}
</style>

<script>
export default {
    // beforeCreate() {
    //   console.log("beforeCreate")
    //   console.log("TOKEN: " + this.$msal.data.accessToken);
    //   console.log("TOKEN STORE: " + this.$store.state.token);
    // },
    // created() {
    //   console.log("created")
    //   console.log("TOKEN: " + this.$msal.data.accessToken);
    //   console.log("TOKEN STORE: " + this.$store.state.token);
    // },
    // beforeMount() {
    //   console.log("beforeMount")
    //   console.log("TOKEN: " + this.$msal.data.accessToken);
    //   console.log("TOKEN STORE: " + this.$store.state.token);
    // },
    // mounted() {
    //     console.log("mounted");
    //     console.log("TOKEN: " + this.$msal.data.accessToken);
    //     console.log("TOKEN STORE: " + this.$store.state.token);
    //     if(this.$msal.isAuthenticated()) {
    //         this.$router.push({ name: 'Home', query: { redirect: '/' } });
    //     }
    //     this.$forceUpdate();
    // },
    // beforeUpdate() {
    //   console.log("beforeUpdate")
    //   console.log("TOKEN: " + this.$msal.data.accessToken);
    //   console.log("TOKEN STORE: " + this.$store.state.token);
    // },
    // updated() {
    //     console.log("updated")
    //     // console.log("Login token: " + this.$msal.data.accessToken);
    //     // this.$store.commit("setToken", this.$msal.data.accessToken);
    //     console.log("TOKEN STORE: " + this.$store.state.token);
    // },
    // beforeDestroy() {
    //     console.log("beforeDestroy");
    //     //   this.$store.commit("setToken", this.$msal.data.accessToken);
    //     console.log("TOKEN: " + this.$msal.data.accessToken);
    //     console.log("TOKEN STORE: " + this.$store.state.token);
    // },
    // destroyed() {
    //   console.log("destroyed")
    // //   this.$store.commit("setToken", this.$msal.data.accessToken);
    //   console.log("TOKEN: " + this.$msal.data.accessToken);
    //   console.log("TOKEN STORE: " + this.$store.state.token);
    // },
    methods : {
        redirectToHome: async function() {

            this.$msal.signIn();

            if(!this.$msal.isAuthenticated()) {
                // If not authenticated, add a path where to redirect after login.
                this.$router.push({ name: 'Home', query: { redirect: '/' } });
            } else {
              this.$router.push({ name: 'Login', query: { redirect: '/login' } });
            }
        }
    }
}
</script>