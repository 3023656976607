<template>
  <div class="alert-wrapper">
    <Alert v-if="$store.state.alerts.resendInviteSuccess" variant="success" @close="closeAlert('resendInviteSuccess')">
      <div>Invite email sent to <strong>{{ $store.state.alerts.resendInviteEmail }}</strong></div>
    </Alert>

    <Alert v-if="$store.state.alerts.resendInviteError" variant="error" @close="closeAlert('resendInviteError')">
      <div>Error resending invite to <strong>{{ $store.state.alerts.resendInviteEmail }}.</strong> Please refresh and try again.</div>
    </Alert>

    <Alert v-if="$store.state.alerts.invalidEmail" variant="error" @close="closeAlert('invalidEmail')">
      <div>Please enter a valid email.</div>
    </Alert>

    <Alert v-if="$store.state.alerts.invalidNonSig" variant="error" @close="closeAlert('invalidNonSig')">
      <div>Nonsig must be exactly 9 digits and can only contain numbers.</div>
    </Alert>

    <Alert v-if="$store.state.alerts.duplicateEmail" variant="error" @close="closeAlert('duplicateEmail')">
      <div>This user already exists in this application. Cannot invite or add duplicate emails.</div>
    </Alert>

    <Alert v-if="$store.state.alerts.emptyEmail" variant="error" @close="closeAlert('emptyEmail')">
      <div>Please enter an email.</div>
    </Alert>

    <!-- <Alert v-if="$store.state.alerts.emptyNonSig" variant="error" @close="closeAlert('emptyNonSig')">
      <div>Please enter a nonsig.</div>
    </Alert> -->

    <Alert v-if="$store.state.alerts.flagError" variant="error" @close="closeAlert('flagError')">
      <div>An error occurred while attempting to flag this user. Please refresh and try again.</div>
    </Alert>

    <Alert v-if="$store.state.alerts.flagPendingError" variant="error" @close="closeAlert('flagPendingError')">
      <div>Cannot flag a pending user.</div>
    </Alert>

    <Alert v-if="$store.state.alerts.groupMembersRemoved" variant="success" @close="closeAlert('groupMembersRemoved')">
      <div>Members successfully removed from {{ this.$store.state.groupName }}.</div>
    </Alert>

    <Alert v-if="$store.state.alerts.groupMembersRemovedError" variant="error" @close="closeAlert('groupMembersRemovedError')">
      <div>Error when removing members from {{ this.$store.state.groupName }}. Please refresh and try again.</div>
    </Alert>

    <Alert v-if="$store.state.alerts.usersAddedSuccess" variant="success" @close="closeAlert('usersAddedSuccess')">
      <div>Member(s) have been successfully added/invited to {{ this.$store.state.groupName }}!</div>
    </Alert>

    <Alert v-if="$store.state.alerts.usersAddedPartialSuccess" variant="error" @close="closeAlert('usersAddedPartialSuccess')">
      <div>Failed to add <strong>some member(s)</strong> to {{ this.$store.state.groupName }}...</div>
      <div v-if="$store.state.alerts.existsOrInvited.length > 0">
        <br>
        <strong>Email(s) exist in application or have been invited already: </strong>
        <span v-for="email in $store.state.alerts.existsOrInvited" v-bind:key="email">
          {{ email }}<span v-if="$store.state.alerts.existsOrInvited.length > 1"><br></span>
        </span>
      </div>
      <div v-if="$store.state.alerts.invalidEmails.length > 0">
        <br>
        <strong>Invalid email(s): </strong>
        <span v-for="email in $store.state.alerts.invalidEmails" v-bind:key="email">
          {{ email }}<span v-if="$store.state.alerts.invalidEmails.length > 1"><br></span>
        </span>
      </div>
      <div v-if="$store.state.alerts.failedUsers.length > 0">
        <br>
        <strong>Error occurred when adding email(s). Try again: </strong>
        <span v-for="email in $store.state.alerts.failedUsers" v-bind:key="email">
          {{ email }}<span v-if="$store.state.alerts.failedUsers.length > 1"><br></span>
        </span>
      </div>
    </Alert>

    <Alert v-if="$store.state.alerts.usersAddedError" variant="error" @close="closeAlert('usersAddedError')">
      <div>Failed to add member(s) to {{ this.$store.state.groupName }}...</div>
      <div v-if="$store.state.alerts.existsOrInvited.length > 0">
        <br>
        <strong>Email(s) exist in application or have been invited: </strong>
        <span v-for="email in $store.state.alerts.existsOrInvited" v-bind:key="email">
          {{ email }}<span v-if="$store.state.alerts.existsOrInvited.length > 1"><br></span>
        </span>
      </div>
      <div v-if="$store.state.alerts.invalidEmails.length > 0">
        <br>
        <strong>Invalid email(s): </strong>
        <span v-for="email in $store.state.alerts.invalidEmails" v-bind:key="email">
          {{ email }}<span v-if="$store.state.alerts.invalidEmails.length > 1"><br></span>
        </span>
      </div>
      <div v-if="$store.state.alerts.failedUsers.length > 0">
        <br>
        <strong>Error occurred when adding email(s). Try again: </strong>
        <span v-for="email in $store.state.alerts.failedUsers" v-bind:key="email">
          {{ email }}<span v-if="$store.state.alerts.failedUsers.length > 1"><br></span>
        </span>
      </div>
    </Alert>
  </div>
</template>

<script>
import Alert from '../components/Alert';

export default {
  name: "Alerts",
  components: {
    Alert,
  },
  methods: {
    closeAlert: function(alertType) {
      this.$store.dispatch('alerts/closeAlert', alertType);
    },
  },       
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .alert-wrapper {
    position: absolute;
    width: 300px;
    right: 0;
    top: 0;
    z-index: 2000;
  }

</style>
