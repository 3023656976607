
const alertsState = {
  duplicateEmail: false,
  emptyEmail: false,
  // emptyNonSig: false,
  invalidEmail: false,
  invalidNonSig: false,
  groupMembersRemoved: false,
  groupMembersRemovedError: false,
  usersAddedSuccess: false,
  usersAddedPartialSuccess: false,
  usersAddedError: false,
  existsOrInvited: [],
  failedUsers: [],
  invalidEmails: [],
  flagError: false,
  flagPendingError: false,
  resendInviteSuccess: false,
  resendInviteError: false,
  resendInviteEmail: '',
}

// Actions 
const actions = {
  setAlertPropValue({ commit }, { propName, value }) {
    commit('SET_ALERT_PROP_VALUE', { propName, value });
  },
  openAlert({ dispatch }, alertType) {
    dispatch('setAlertPropValue', { propName: alertType, value: true });
  },
  closeAlert({ dispatch }, alertType) {
    dispatch('setAlertPropValue', { propName: alertType, value: false });

    if (alertType === 'resendInviteSuccess' || alertType === 'resendInviteError') {
      dispatch('setAlertPropValue', { propName: 'resendInviteEmail', value: '' });
    }
    
    if (alertType === 'usersAddedPartialSuccess') {
      dispatch('setAlertPropValue', { propName: 'existsOrInvited', value: [] });
      dispatch('setAlertPropValue', { propName: 'failedUsers', value: [] });
      dispatch('setAlertPropValue', { propName: 'invalidEmails', value: [] });
    }
  },
}

// Mutations
const mutations = {
  SET_ALERT_PROP_VALUE(state, { propName, value }) {
    state[propName] = value;
  },
}

export default {
  namespaced: true,
  state: alertsState,
  actions,
  mutations,
}