<template>
  <Modal @close="close">
    <form enctype="multipart/form-data" novalidate>
      <h3 style="margin-bottom: 20px;">Upload members</h3>
      <strong style="text-decoration: underline;">File requirements</strong>
      <ol style="margin-bottom: 20px; font-size: 14px;">
        <li>File type must be <strong>.csv</strong></li>
        <li>No <strong>spaces</strong> or <strong>empty values</strong></li>
        <li>Please upload no more than <strong>100 users</strong> from a file at once</li>
      </ol>
      <div class="dropbox" v-if="parsedUsers.length==0">
        <input type="file" :name="uploadFieldName" :disabled="fileInputIsLoading" v-on:change="processFile($event);"
          accept=".csv" class="input-file" />
          <p>
            Drag your file here to begin<br> or click to browse
          </p>
      </div>
      <p v-if="fileInputIsLoading">
        <b-icon icon="arrow-repeat" animation="spin-pulse"></b-icon> Uploading file...
      </p>
    </form>
    <h4 class="parsed-users-table-title" v-if="parsedUsers.length>0">
      <b-icon icon="exclamation-circle" style="margin-right: 10px;"></b-icon>Please review emails before inviting members
    </h4>
    <div class="parsed-users-table-wrapper" v-if="parsedUsers.length>0">
      <table class="parsed-users-table">
        <caption style="display: none;"></caption>
        <th></th>
        <tr v-for="parsedUser in parsedUsers" v-bind:key="parsedUser">
          <td>
            {{ parsedUser }}
          </td>
        </tr>
      </table>
    </div>
    <div style="margin-top: 20px;">
      <div v-if="bulkInviteSuccess && parsedUsers.length == 0" class="bulkInviteSuccess">
        <b-icon icon="check-circle" animation="fade" font-scale="1.25" style="margin-right: 10px;"></b-icon>
        <span>Users successfully invited to <strong>{{ $store.state.groupName }}</strong>. New invites are being generated.</span>
      </div>
      <div v-if="bulkInviteError && parsedUsers.length == 0" class="bulkInviteError">
        <b-icon icon="exclamation-circle" animation="fade" font-scale="1.25" style="margin-right: 10px;"></b-icon>
        <span><strong>There was an error inviting members.</strong> Please review your file ( {{ uploadFieldName }} ) and ensure it complies with the requirements for file upload.</span>

        <!-- Users error because they have already been invited or exist -->
        <div v-if="bulkInviteExistingInvited.length > 0">
          <h4 style="color: #0D2F42;">Users already invited or existing</h4>
          <div class="parsed-users-table-wrapper">
            <table class="parsed-users-table" style="color: #0D2F42;">
              <caption style="display: none;"></caption>
              <th></th>
              <tr v-for="email in bulkInviteExistingInvited" v-bind:key="email">
                <td>
                  {{ email }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Users failed -->
        <div v-if="bulkInviteFails.length > 0">
          <h4 style="color: #0D2F42;">Failed users</h4>
          <div class="parsed-users-table-wrapper">
            <table class="parsed-users-table" style="color: #0D2F42;">
              <caption style="display: none;"></caption>
              <th></th>
              <tr v-for="bulkInviteFail in bulkInviteFails" v-bind:key="bulkInviteFail">
                <td>
                  {{ bulkInviteFail }}
                </td>
              </tr>
            </table>
          </div>
        </div>

      </div>
    </div>

    <template v-slot:buttons>
      <button v-if="!bulkUploadComplete" class="modal-button-secondary" style="margin-right: 15px;" v-on:click="close">Cancel</button>
      <button v-if="!bulkUploadComplete" class="modal-button" :disabled="parsedUsers == null || parsedUsers.length == 0 || fileInputIsInitial" v-on:click="bulkInvite">Upload Members</button>
      <button v-if="bulkUploadComplete && parsedUsers.length == 0" class="modal-button" v-on:click="close">Ok</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import axios from 'axios';
import { apiPath } from '../../authConfig';
axios.defaults.baseURL = apiPath;
import Papa from 'papaparse';
import jwt_decode from "jwt-decode";

export default {
  name: "BulkInviteModal",
  components: {
    Modal,
  },
  data: function () {
    return {
      fileInputIsInitial:true,
      fileInputIsLoading:false,
      uploadFieldName: '',
      parsedUsers:[],
      bulkInviteSuccess:false,
      bulkInviteError:false,
      bulkUploadComplete:false,
      bulkInviteFails:[],
      bulkInviteExistingInvited:[],
    }
  },
  methods: {
    processFile: function(csv) {
      this.fileInputIsLoading = true;
      this.bulkUploadComplete = false;
      this.uploadFieldName = csv.target.files[0].name;
      Papa.parse(csv.target.files[0], {
        complete: (results) => {
          let uniqueEmails = [...new Set(results.data[0])]; // Remove duplicate emails
          this.parsedUsers = uniqueEmails;
        }
      });
      this.fileInputIsLoading = false;
      this.fileInputIsInitial = false;
    },
    bulkInvite: async function() {
      let decodedToken = jwt_decode(this.$store.state.token);
      let createdBy = decodedToken.oid;
      let users = [];

      // format user array for API call
      for(let i=0; i<this.parsedUsers.length; i++) {
        users[i] = {
          "otherMails": [
            this.parsedUsers[i]
          ]
        }
      }

      await axios.post('/BulkInvite', {
        groupId: this.$store.state.groupId,
        createdBy: createdBy,
        appId: 'GYPass-Admin-Portal',
        users: users
      },
      {
        headers:{
          Authorization: this.$store.state.token,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if(response.data.status == 200) {
          this.bulkInviteSuccess = true;
          this.bulkUploadComplete = true;
          this.parsedUsers = [];
          this.fileInputIsInitial = true;
          this.fileInputIsLoading = false;
        } else {
          let existingOrInvited = response.data.existingOrInvited;
          let failedUsers = response.data.failedUsers;

          if(existingOrInvited == null || existingOrInvited == undefined) {
            this.bulkInviteExistingInvited = [];
            this.bulkInviteError = false;
            this.bulkInviteSuccess = true;
          } else {
            this.bulkInviteExistingInvited = existingOrInvited;
            this.bulkInviteError = true;
            this.bulkInviteSuccess = false;
          }

          if(failedUsers == null ||  failedUsers == undefined) {
            this.bulkInviteFails = [];
            this.bulkInviteError = false;
            this.bulkInviteSuccess = true;
          } else {
            this.bulkInviteFails = failedUsers;
            this.bulkInviteError = true;
            this.bulkInviteSuccess = false;
          }
          
          this.bulkUploadComplete = true;
          this.parsedUsers = [];
          this.fileInputIsInitial = true;
          this.fileInputIsLoading = false;
        }
        this.$emit('bulk-invite-completed');
      })
      .catch(() => {
        this.bulkInviteError = true;
        this.bulkUploadComplete = true;
        this.parsedUsers = [];
        this.fileInputIsInitial = true;
        this.fileInputIsLoading = false;
        this.uploadFieldName = '';
      })
    },
    close: function() {
      this.$emit('close');
    },
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
  .dropbox {
    outline: 2px dashed #0D2F42;
    border-radius: 20px;
    outline-offset: -10px;
    background: #0d2f4207;
    color: #0D2F42;
    padding: 10px 10px;
    min-height: 100px;
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0;
    width: 100%;
    height: 100%;
    max-height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: #0d2f421a;
  }
  
  .dropbox p {
    text-align: center;
    padding: 10px 0;
  }

  .parsed-users-table-wrapper {
    max-height: 200px;
    overflow: scroll;
  }

  .parsed-users-table-title {
    color: #C50202;
    margin: 20px 0px;
  }

  .parsed-users-table {
    width: 100%;
  }

  .parsed-users-table tr:nth-child(odd) {
    background-color: rgba(13, 47, 66, 0.2);
  }

  .parsed-users-table tr:nth-child(even) {
    background-color: rgba(13, 47, 66, 0.05);
  }

  .parsed-users-table td {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .bulkInviteError {
    color: #C50202;
  }

</style>
