<template>
  <Modal @close="close">
    <h3>Resend Invite</h3>
    <div style="height: 20px;"></div>
    <div style="text-align: center;">
        Are you sure you would like to resend the invite  to <strong>{{ memberToResendInvite }}?</strong>
    </div>
    <template v-slot:buttons>
      <button class="modal-button-secondary" style="margin-right: 15px;" v-on:click="close">Cancel</button>
      <button class="modal-button" v-on:click="resendInvite(memberToResendInvite, memberToResendInviteStatus)">Resend</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import axios from 'axios';
import { apiPath } from '../../authConfig';
axios.defaults.baseURL = apiPath;

export default {
  name: "ResendInviteModal",
  components: {
    Modal,
  },
  props: {
    memberToResendInvite: String,
    memberToResendInviteStatus: String
  },
  methods: {
    resendInvite: async function(email, userStatus) {
      this.$store.dispatch('alerts/setAlertPropValue', { propName: 'resendInviteEmail', value: email });

      if(userStatus === 'Pending') {
        await axios.post('/ResendInvite', {
          email: email
        },
        {
          headers:{
            Authorization: this.$store.state.token,
            'Content-Type': 'application/json'
          }
        }
        )
        .then(response => {
          if(response.data == 'ERROR') {
            this.$store.dispatch('alerts/openAlert', 'resendInviteError');
          } else {
            this.$store.dispatch('alerts/openAlert', 'resendInviteSuccess');
          }
        })
        .catch(() => {
          this.$store.dispatch('alerts/openAlert', 'resendInviteError');
        })
      } else {
        this.$store.dispatch('alerts/openAlert', 'resendInviteError');
      }

      this.$emit('invite-resend-completed')
    },
    close: function() {
      this.$emit('close');
    },
  },
  
};
</script>
