<template>
  <div class="modal">
    <div class="modal-background">

      <!-- Alerts -->

      <Alerts />

      <!-- Modals -->
      
      <RemoveMembersModal 
        v-if="showRemoveMembersModal"
        v-bind:members-to-delete="selectedRowsEmails"
        @close="showRemoveMembersModal = false"
        @members-removed="membersRemoveCompleted(true)"
        @members-remove-failed="membersRemoveCompleted(false)"
      />

      <ResendInviteModal
        v-if="showResendInviteModal"
        v-bind:member-to-resend-invite="selectedRowsEmails[0]"
        v-bind:member-to-resend-invite-status="selectedRowsStatus[0]"
        @close="showResendInviteModal = false"
        @invite-resend-completed="inviteResendCompleted"
      />

      <BulkInviteModal
        v-if="showBulkInviteModal"
        @close="showBulkInviteModal = false"
        @bulk-invite-completed="bulkInviteCompleted"
      />

      <FlagUserModal
        v-if="showFlagUserModal"
        @close="showFlagUserModal = false"
        @flag-user-submitted="(comment) => {flaggedComments = comment; flagUser(flagClickedID, flagClickedMember)}"
      />

      <!-- Modals End -->

      <template class="sidebar-wrapper">

        <b-sidebar class="sidebar" id="sidebar-right" right shadow>

          <div class="sidebar-inner">
            <div class="row addTitle">
              Add member to <strong>{{ $store.state.groupName }}</strong>
            </div>
            
              <div class="row add-wrapper" v-if="!addToGroupLoading">
                <div class="addInvitedUser-wrapper">
                  <b-form-input for="email" type="email" placeholder="Enter email" class="addInvitedUser" style="margin-top: 15px;" v-model="newUserEmail"></b-form-input>
                </div>
                <div v-if="$store.state.groupNonSigFlag === true" class="addInvitedUser-wrapper" style="margin-top: 60px;">
                  <b-form-input class="addInvitedUser" v-model="newUserNonsig" placeholder="Nonsig (optional)"></b-form-input>
                </div>
                <div class="addInvitedUserBtn-wrapper">
                  <button type="submit" v-on:click="setInvitedUsers(newUserEmail, newUserNonsig)" class="addEmailBtn">Add User</button>
                </div>
              </div>
              <div style="width: 100%; height: auto; margin-top: 20px;" v-if="addToGroupLoading">
                <b-icon icon="arrow-repeat" animation="spin-pulse" font-scale="1.5" style="margin-right: 10px;"></b-icon>
                <span class="loadingMsg">Adding and inviting users</span>
              </div>
              <div v-for="invitedUser in invitedUsers" v-bind:key="invitedUser">
                <!-- <div style="margin-left: -10px; margin-right: 10px; margin-top: 4px; text-transform: lowercase;" class="nameIcon">{{ invitedUser.email[0] }}</div> -->
                <table class="newUserEmail">
                  <caption style="display: none;"></caption>
                  <th></th>
                  <tr>
                    <td class="newUserEmail-inner">{{ invitedUser.email }}</td>
                    <td class="newUserNonsig-inner">{{ invitedUser.nonsig }}</td>
                    <td v-if="!addToGroupLoading">
                      <button v-bind:id="invitedUser.email" class="removeUserEmailBtn" v-on:click="removeUserEmail(invitedUser.email)">-</button>
                      <b-tooltip v-bind:target="invitedUser.email" triggers="hover" variant="primary" placement="center">
                        Remove {{ invitedUser.email }}
                      </b-tooltip>
                    </td>
                  </tr>
                  <!-- <div style="color: white;"><strong>{{ invitedUserNonSig }}</strong></div> -->
                </table>
              </div>

              <div class="buttonsWrapper row d-flex" v-if="!addToGroupLoading">
                <div class="addMembers-wrapper"><button class="addMembers" v-if="invitedUsers.length>0" v-on:click="addNewMember(invitedUsers)">Add {{ invitedUsers.length }} Member<span v-if="invitedUsers.length>1">s</span></button></div>
                <div v-if="invitedUsers.length>0" class="cancelBtn-wrapper"><button class="cancelBtn"  v-on:click="clearEmails()">Clear</button></div>
              </div>

              <!-- Loading message at bottom of modal when groups members are loading -->
              <div class="addNewMembersLoading" v-if="addNewMembersLoading">
                <div class="d-flex load-wrapper">
                  <b-icon icon="arrow-repeat" animation="spin-pulse" font-scale="1"></b-icon>
                  <div class="loadingMsg">Loading...</div>
                </div>
              </div>
              
              <div class="edit-msg" v-if="userAddedSuccess">
                <b-icon icon="check-circle" animation="fade" font-scale="1.25" style="margin-right: 10px;"></b-icon>
                <span> <strong>{{ invitedUserEmailMsg }}</strong> was added to <strong>{{ $store.state.groupName }}</strong> successfully!</span>
                <span v-if="inviteSuccess"> <strong>{{ invitedUserEmailMsg }}</strong> has not been invited to <strong>{{ $store.state.groupName }}</strong> yet. A new invite is being generated.</span>
              </div>
              <div v-if="inviteSuccess">
                <b-icon icon="check-circle" animation="fade" font-scale="1.25" style="margin-right: 10px;"></b-icon>
                <span> <strong>{{ invitedUserEmailMsg }}</strong> has been successfully invited to <strong>{{ $store.state.groupName }}</strong>. A new invite is being generated.</span>
              </div>
              <div class="edit-msg" v-if="userAddedError">
                <b-icon icon="exclamation-circle" animation="fade" font-scale="1.25" style="margin-right: 10px;"></b-icon>
                <span> Error when adding <strong>{{ invitedUserEmailMsg }}</strong> to <strong>{{ $store.state.groupName }}</strong>. Please refresh and try again.</span>
              </div>
              <div class="edit-msg" v-if="inviteError">
                <div>
                  <b-icon icon="exclamation-circle" animation="fade" font-scale="1.25" style="margin-right: 10px;"></b-icon>
                  <span> Error when inviting <strong>{{ invitedUserEmailMsg }}</strong>. Please refresh and try again.</span>
                </div>
              </div>
            
          </div>

        </b-sidebar>

      </template>

      <b-container fluid class="modal-inner">
        <b-row class="table-header-logo-wrapper">
          <img style="font-size: 12px;" :src="$store.state.groupLogoURL" :alt="$store.state.groupName + ' logo'" v-if="$store.state.groupLogoURL" />
          <img style="font-size: 12px;" src="../assets/images/Wingfoot_0D2F42.svg" alt="Goodyear Wingfoot logo" v-if="!$store.state.groupLogoURL" />
        </b-row>

        <b-row class="tableTitle-wrapper">
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="tableTitle">
            Manage application members for <strong>{{ $store.state.groupName }}</strong>
          </b-col>
          <div v-on:click="closeGroup()" class="closeBtn">&#10006;</div>
        </b-row>

        <div class="row actions-wrapper">
          <div class="col-xs-12 col-sm-12 col-md-6 add-members-wrapper">
            <b-button v-on:click="clearEmails()" v-b-toggle.sidebar-right class="togglePanel">
              <b-icon icon="plus-circle-fill" style="margin-right: 8px;"></b-icon> Add new members
            </b-button>
            <b-button v-on:click="openModal('showBulkInviteModal', '')" class="primaryButton bulkInviteBtn">
              <b-icon icon="upload" style="margin-right: 8px;"></b-icon> Upload members from file
            </b-button>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 search-wrapper">
            <div class="input-group search-input-group">
              <span class="input-group-btn">
                <b-button v-on:click="searchGroupMembers" class="primaryButton search-btn">Search</b-button>
              </span>
              <b-form-input v-model="searchPatternInputValue" class="search-input"></b-form-input>
              <span class="input-group-btn">
                <b-button v-on:click="resetSearchGroupMembers" class="secondaryButton search-btn">Reset</b-button>
              </span>
              
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3">
            <div class="row">
              <div class="col-xs-6">
                <div class="resend-invite-wrapper">
                  <h5 v-bind:class="[selectedRowsStatus[0] !== 'Active' && selectedRowsIds.length===1 ? 'action-label' : 'action-label-hidden']">
                    Resend invite to <strong>{{ selectedRowsEmails[0] }}</strong>
                  </h5><br/>
                  <button class="resend-invite-btn" style="background-color: transparent; border: none;" :disabled="selectedRowsStatus[0] === 'Active' || selectedRowsIds.length===0 || selectedRowsIds.length>1" v-on:click="openModal('showResendInviteModal', '')">
                    <b-icon v-bind:class="[selectedRowsStatus[0] !== 'Active' && selectedRowsIds.length===1 ? 'action-icon' : 'action-icon-disabled']" 
                      icon="envelope-fill" font-scale="1.7"></b-icon>
                  </button>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="delete-wrapper">
                  <h5 v-bind:class="[selectedRowsIds.length>0 ? 'action-label' : 'action-label-hidden']">
                    Remove <strong style="font-weight: 700;">({{ selectedRowsIds.length }})</strong> Member<span v-if="selectedRowsIds.length>1">s</span>
                  </h5><br/>
                  <button class="delete-btn" style="background-color: transparent; border: none;" :disabled="selectedRowsIds.length==0" v-on:click="openModal('showRemoveMembersModal', '')">
                    <b-icon v-bind:class="[selectedRowsIds.length>0 ? 'action-icon' : 'action-icon-disabled']" 
                      icon="trash-fill" font-scale="1.7"></b-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-row class="table-wrapper">
          <table class="table">
            <caption style="display: none;"></caption>
            <th></th>
            <tr class="tableHeader">
              <td class="tableHeader-column1" @click="changeMembersSorting('name')">
                <div class="tableHeader-title">
                  Name
                  <b-icon icon="arrow-up" v-if="membersSortingColumn === 'name'" class="sort-order-transition" v-bind:style="{transform: `rotate(${membersSortingOrderRotateDeg}deg)`}" font-scale="1.1"></b-icon>
                </div>
              </td>
              <td class="tableHeader-column2">
                <div class="tableHeader-title" @click="changeMembersSorting('email')">
                  Email
                  <b-icon icon="arrow-up" v-if="membersSortingColumn === 'email'" class="sort-order-transition" v-bind:style="{transform: `rotate(${membersSortingOrderRotateDeg}deg)`}" font-scale="1.1"></b-icon>
                </div>
              </td>
              <td class="tableHeader-column3">
                <div class="tableHeader-title">
                  <b-dropdown toggle-class="members-status-dropdown">
                    <template #button-content>
                      Registration Status &#9662;
                      <template v-if="registrationStatusFilter.length === 1 && registrationStatusFilter[0] === 'Active'">(Active)</template>
                      <template v-if="registrationStatusFilter.length === 1 && registrationStatusFilter[0] === 'Pending'">(Pending)</template>
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox-group v-model="registrationStatusFilter">
                        <b-form-checkbox value="Active">Active</b-form-checkbox>
                        <b-form-checkbox value="Pending">Pending</b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </td>
              <td class="tableHeader-column6">
                <div id="tooltip-target-1" v-on:click="selectAll(groupMembers)" class="mainSelectionCircle">
                  <b-icon icon="check" class="check-icon" height="0.75em" font-scale="1.3"></b-icon>
                </div>
                <b-tooltip target="tooltip-target-1" triggers="hover" variant="primary">
                  {{ tooltipText }}
                </b-tooltip>
              </td>
            </tr>
            <tr class="spacer" style="border-top: none;">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <!-- CHANGE ID TO MATCH LOCAL RUNNING BRANCH -->
            <!-- Local Environment Flagged Extension -->
            <div class="tableRow-wrapper" v-if="$store.state.currentDomain == 'http://localhost:8080'">
            <!-- CHANGE ID TO MATCH LOCAL RUNNING BRANCH -->
              <tr v-bind:class="[(groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged ? 'userRow-flagged' : 'tableRow'), 
              (selectedRowsIds.includes(groupMember.id) ? 'userRow-selected' : 'tableRow')]" class="tableRow" v-for="groupMember in currentPageGroupMembers" v-bind:key="groupMember.id">

                <td v-if="groupMembers.length>0" class="tableData">
                  <b-icon v-if="flagLoading && (flagClickedID == groupMember.id)" icon="arrow-clockwise" animation="spin-pulse" font-scale="1" class="flagLoading-icon"></b-icon>
                  <!-- CHANGE ID TO MATCH LOCAL RUNNING BRANCH -->
                  <b-icon id="tooltip-target-2" v-if="groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged" v-on:click="flagUser(groupMember.id, groupMember)" icon="flag-fill" font-scale="1.3" class="flag-icon-unflagged"></b-icon>
                  <b-icon id="tooltip-target-3" v-if="!groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged" v-on:click="openModal('showFlagUserModal', groupMember)" icon="flag" font-scale="1.3" class="flag-icon"></b-icon>
                  {{ groupMember.displayName }}
                </td>
                <td v-if="groupMembers.length>0" class="tableData">{{ groupMember.otherMails[0] }}</td>
                <td v-if="groupMembers.length>0">
                  <div v-bind:class="[groupMember.userStatus == 'Active' ? 'tableDataActive' : 'tableDataPending']">{{ groupMember.userStatus }} <span v-if="groupMember.userStatus == 'Pending'" style="padding-left: 5px;"><b-icon icon="exclamation-circle" /></span></div>
                </td>
                <td v-if="groupMembers.length>0">
                  <div v-bind:class="[selectedRowsIds.includes(groupMember.id) ? 'selectionCircle-selected' : 'selectionCircle']" v-on:click="selectRow(groupMember)" class="selectionCircle">
                    <b-icon v-if="selectedRowsIds.includes(groupMember.id)" icon="check" class="check-icon" height="0.7em" font-scale="1.2"></b-icon>
                  </div>
                </td>
              </tr>
            </div>

            <!-- Dev Environment Flagged Extension -->
            <div class="tableRow-wrapper" v-if="$store.state.currentDomain == 'https://admin.goodyearpass.net'">
              <tr v-bind:class="[(groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged ? 'userRow-flagged' : 'tableRow'), 
              (selectedRowsIds.includes(groupMember.id) ? 'userRow-selected' : 'tableRow')]" class="tableRow" v-for="groupMember in currentPageGroupMembers" v-bind:key="groupMember.id">

                <td v-if="groupMembers.length>0" class="tableData">
                  <b-icon v-if="flagLoading && (flagClickedID == groupMember.id)" icon="arrow-clockwise" animation="spin-pulse" font-scale="1" class="flagLoading-icon"></b-icon>
                  <b-icon id="tooltip-target-2" v-if="groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged" v-on:click="flagUser(groupMember.id, groupMember)" icon="flag-fill" font-scale="1.3" class="flag-icon-unflagged"></b-icon>
                  <b-icon id="tooltip-target-3" v-if="!groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged" v-on:click="openModal('showFlagUserModal', groupMember)" icon="flag" font-scale="1.3" class="flag-icon"></b-icon>
                  {{ groupMember.displayName }}
                </td>
                <td v-if="groupMembers.length>0" class="tableData">{{ groupMember.otherMails[0] }}</td>
                <td v-if="groupMembers.length>0">
                  <div v-bind:class="[groupMember.userStatus == 'Active' ? 'tableDataActive' : 'tableDataPending']">{{ groupMember.userStatus }} <span v-if="groupMember.userStatus == 'Pending'" style="padding-left: 5px;"><b-icon icon="exclamation-circle" /></span></div>
                </td>
                <td v-if="groupMembers.length>0">
                  <div v-bind:class="[selectedRowsIds.includes(groupMember.id) ? 'selectionCircle-selected' : 'selectionCircle']" v-on:click="selectRow(groupMember)" class="selectionCircle">
                    <b-icon v-if="selectedRowsIds.includes(groupMember.id)" icon="check" class="check-icon" height="0.7em" font-scale="1.2"></b-icon>
                  </div>
                </td>
              </tr>
            </div>

            <!-- PR Environment Flagged Extension -->
            <div class="tableRow-wrapper" v-if="$store.state.currentDomain == 'https://lemon-sea-01e165a0f-71.eastus2.azurestaticapps.net'">
              <tr v-bind:class="[(groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged ? 'userRow-flagged' : 'tableRow'), 
              (selectedRowsIds.includes(groupMember.id) ? 'userRow-selected' : 'tableRow')]" class="tableRow" v-for="groupMember in currentPageGroupMembers" v-bind:key="groupMember.id">

                <td v-if="groupMembers.length>0" class="tableData">
                  <b-icon v-if="flagLoading && (flagClickedID == groupMember.id)" icon="arrow-clockwise" animation="spin-pulse" font-scale="1" class="flagLoading-icon"></b-icon>
                  <b-icon id="tooltip-target-2" v-if="groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged" v-on:click="flagUser(groupMember.id, groupMember)" icon="flag-fill" font-scale="1.3" class="flag-icon-unflagged"></b-icon>
                  <b-icon id="tooltip-target-3" v-if="!groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged" v-on:click="openModal('showFlagUserModal', groupMember)" icon="flag" font-scale="1.3" class="flag-icon"></b-icon>
                  {{ groupMember.displayName }}
                </td>
                <td v-if="groupMembers.length>0" class="tableData">{{ groupMember.otherMails[0] }}</td>
                <td v-if="groupMembers.length>0">
                  <div v-bind:class="[groupMember.userStatus == 'Active' ? 'tableDataActive' : 'tableDataPending']">{{ groupMember.userStatus }}</div>
                </td>
                <td v-if="groupMembers.length>0">
                  <div v-bind:class="[selectedRowsIds.includes(groupMember.id) ? 'selectionCircle-selected' : 'selectionCircle']" v-on:click="selectRow(groupMember)" class="selectionCircle">
                    <b-icon v-if="selectedRowsIds.includes(groupMember.id)" icon="check" class="check-icon" height="0.7em" font-scale="1.2"></b-icon>
                  </div>
                </td>
              </tr>
            </div>

            <!-- Test Environment Flagged Extension -->
            <div class="tableRow-wrapper" v-if="$store.state.currentDomain == 'https://admin.goodyearpass.org'">
              <tr v-bind:class="[(groupMember.extension_2cdd043be83644a49b7efef1a4fc8be6_Flagged ? 'userRow-flagged' : 'tableRow'), 
              (selectedRowsIds.includes(groupMember.id) ? 'userRow-selected' : 'tableRow')]" class="tableRow" v-for="groupMember in currentPageGroupMembers" v-bind:key="groupMember.id">

                <td v-if="groupMembers.length>0" class="tableData">
                  <b-icon v-if="flagLoading && (flagClickedID == groupMember.id)" icon="arrow-clockwise" animation="spin-pulse" font-scale="1" class="flagLoading-icon"></b-icon>
                  <b-icon id="tooltip-target-2" v-if="groupMember.extension_2cdd043be83644a49b7efef1a4fc8be6_Flagged" v-on:click="flagUser(groupMember.id, groupMember)" icon="flag-fill" font-scale="1.3" class="flag-icon-unflagged"></b-icon>
                  <b-icon id="tooltip-target-3" v-if="!groupMember.extension_2cdd043be83644a49b7efef1a4fc8be6_Flagged" v-on:click="openModal('showFlagUserModal', groupMember)" icon="flag" font-scale="1.3" class="flag-icon"></b-icon>
                  {{ groupMember.displayName }}
                </td>
                <td v-if="groupMembers.length>0" class="tableData">{{ groupMember.otherMails[0] }}</td>
                <td v-if="groupMembers.length>0">
                  <div v-bind:class="[groupMember.userStatus == 'Active' ? 'tableDataActive' : 'tableDataPending']">{{ groupMember.userStatus }} <span v-if="groupMember.userStatus == 'Pending'" style="padding-left: 5px;"><b-icon icon="exclamation-circle" /></span></div>
                </td>
                <td v-if="groupMembers.length>0">
                  <div v-bind:class="[selectedRowsIds.includes(groupMember.id) ? 'selectionCircle-selected' : 'selectionCircle']" v-on:click="selectRow(groupMember)" class="selectionCircle">
                    <b-icon v-if="selectedRowsIds.includes(groupMember.id)" icon="check" class="check-icon" height="0.7em" font-scale="1.2"></b-icon>
                  </div>
                </td>
              </tr>
            </div>

            <!-- Prod Environment Flagged Extension -->
            <div class="tableRow-wrapper" v-if="$store.state.currentDomain == 'https://admin.goodyearpass.com'">
              <tr v-bind:class="[(groupMember.extension_a3ba1f5147054d2a94ca8810f1b0c368_Flagged ? 'userRow-flagged' : 'tableRow'), 
              (selectedRowsIds.includes(groupMember.id) ? 'userRow-selected' : 'tableRow')]" class="tableRow" v-for="groupMember in currentPageGroupMembers" v-bind:key="groupMember.id">

                <td v-if="groupMembers.length>0" class="tableData">
                  <b-icon v-if="flagLoading && (flagClickedID == groupMember.id)" icon="arrow-clockwise" animation="spin-pulse" font-scale="1" class="flagLoading-icon"></b-icon>
                  <b-icon id="tooltip-target-2" v-if="groupMember.extension_a3ba1f5147054d2a94ca8810f1b0c368_Flagged" v-on:click="flagUser(groupMember.id, groupMember)" icon="flag-fill" font-scale="1.3" class="flag-icon-unflagged"></b-icon>
                  <b-icon id="tooltip-target-3" v-if="!groupMember.extension_a3ba1f5147054d2a94ca8810f1b0c368_Flagged" v-on:click="openModal('showFlagUserModal', groupMember)" icon="flag" font-scale="1.3" class="flag-icon"></b-icon>
                  {{ groupMember.displayName }}
                </td>
                <td v-if="groupMembers.length>0" class="tableData">{{ groupMember.otherMails[0] }}</td>
                <td v-if="groupMembers.length>0">
                  <div v-bind:class="[groupMember.userStatus == 'Active' ? 'tableDataActive' : 'tableDataPending']">{{ groupMember.userStatus }} <span v-if="groupMember.userStatus == 'Pending'" style="padding-left: 5px;"><b-icon icon="exclamation-circle" /></span></div>
                </td>
                <td v-if="groupMembers.length>0">
                  <div v-bind:class="[selectedRowsIds.includes(groupMember.id) ? 'selectionCircle-selected' : 'selectionCircle']" v-on:click="selectRow(groupMember)" class="selectionCircle">
                    <b-icon v-if="selectedRowsIds.includes(groupMember.id)" icon="check" class="check-icon" height="0.7em" font-scale="1.2"></b-icon>
                  </div>
                </td>
              </tr>
            </div>

            <tr v-if="groupMembers.length==0 && !groupMembersLoading">
              <strong>This application does not contain any members.</strong>
            </tr>
            <tr v-if="groupMembers.length !==0 && sortedFilteredGroupMembers.length === 0 && !groupMembersLoading">
              <strong>There are no group members that match your search.</strong>
            </tr>

          </table>
          
        </b-row>

        <div class="row members-pagination-wrapper" v-if="!groupMembersLoading && groupMembers.length !== 0 && sortedFilteredGroupMembers.length !== 0">
          <div class="col-xs-12 col-sm-8 members-pagination">
            <paginate
              v-model="membersPage"
              :page-count="sortedFilteredMembersPageCount"
              :page-range="3"
              :margin-pages="2"
              prev-text='<span class="glyphicon glyphicon glyphicon-chevron-left prev-next-page-icon" aria-hidden="true"></span>'
              next-text='<span class="glyphicon glyphicon glyphicon-chevron-right prev-next-page-icon" aria-hidden="true"></span>'
              :container-class="'pagination'"
              :page-link-class="'page-link'"
              :prev-link-class="'prev-next-page-link'"
              :next-link-class="'prev-next-page-link'"
            >
            </paginate>
          </div>
          <div class="col-xs-12 col-sm-4 members-per-page">
            <div class="rows-per-page-label">
              Rows per page:
            </div>
            <div>
              <select v-model="membersPerPage" class="form-control input-sm">
                <option v-bind:value="10">10</option>
                <option v-bind:value="25">25</option>
                <option v-bind:value="50">50</option>
              </select>
            </div>
            <div class="current-page-range">
              {{currentPageRange}}
            </div>
          </div>
        </div>

      </b-container>

      <div class="groupMembersLoading" v-if="groupMembersLoading">
        <div class="d-flex load-wrapper">
          <b-icon icon="arrow-repeat" animation="spin-pulse" font-scale="1"></b-icon>
          <div class="loadingMsg">Loading...</div>
        </div>
      </div>

    </div>
    <div class="modalOverlay" v-on:click="closeGroup()"></div>
  </div>
</template>

<script>
import axios from 'axios';
import { apiPath } from '../authConfig';
axios.defaults.baseURL = apiPath;
import jwt_decode from "jwt-decode";
import Alerts from '../components/Alerts';
import RemoveMembersModal from '../components/modals/RemoveMembersModal';
import ResendInviteModal from '../components/modals/ResendInviteModal';
import BulkInviteModal from '../components/modals/BulkInviteModal';
import FlagUserModal from '../components/modals/FlagUserModal';

export default {
  name: "GroupMembers",
  components: {
    Alerts,
    RemoveMembersModal,
    ResendInviteModal,
    BulkInviteModal,
    FlagUserModal,
  },
  data: function () {
    return {
      groupMembers:[],
      groupMembersLoading: false,
      showModal:false,
      showInviteModal:false,
      newUserEmail:'',
      newUserNonsig:'',
      invitedUserEmail:'',
      invitedUserNonsig: '',
      invitedUsers:[],
      invitedUsersLength:0,
      invitedUserEmailMsg:'',
      invitedUserEmailFirstLetters:[],
      userId:'',
      userExists:'',
      userStatus:'',
      userAddedSuccess:false,
      userAddedError:false,
      inviteSuccess:false,
      inviteError:false,
      flagLoading:false,
      flagClickedID:'',
      showRemoveMembersModal:false,
      isInvitedAddedModal:false,
      showResendInviteModal:false,
      showBulkInviteModal:false,
      showFlagUserModal:false,
      flaggedComments:'',
      selectedRowsIds:[],
      selectedRowsEmails:[],
      selectedRowsStatus:[],
      rowIsSelected:false,
      tooltipText:'Select All',
      iconText:'',
      invitedUsersSuccess:[],
      invitedUsersFail:[],
      addedUsersSuccess:[],
      addedUsersFail:[],
      addNewMembersLoading:false,
      invitedAddedUsersAlert:false,
      selectedResendEmail:null,
      membersSortingOrder: 'ASC', // default
      membersSortingColumn: 'name', // default
      registrationStatusFilter: [],
      searchPatternInputValue:'',
      searchPattern:'',
      membersPage: 1,
      membersPerPage: 10,
      addToGroupLoading: false,
    }
  },
  watch: {
    registrationStatusFilter: function () {
      this.membersPage = 1;
    },
    membersPerPage: function () {
      this.membersPage = 1;
    }
  },
  computed: {
    membersSortingOrderRotateDeg: function() {
      return this.membersSortingOrder === 'ASC' ? 0 : 180;
    },
    flaggedCommentsEmpty: function() {
      return this.flaggedComments == '';
    },
    sortedFilteredMembersPageCount: function() {
      return Math.ceil(this.sortedFilteredGroupMembers.length / this.membersPerPage);
    },
    currentPageRange: function() {
      let first = (this.membersPage - 1) * this.membersPerPage + 1;
      let last = this.membersPage === this.sortedFilteredMembersPageCount ? this.sortedFilteredGroupMembers.length : first + this.membersPerPage - 1;
      return `${first} - ${last} of ${this.sortedFilteredGroupMembers.length}`
    },
    sortedFilteredGroupMembers: function() {
      let members = [... this.groupMembers];

      // Filter by registration status
      if(this.registrationStatusFilter.length === 1) {
        members = members.filter(user => user.userStatus === this.registrationStatusFilter[0]);
      }

      // Search
      if (this.searchPattern && this.searchPattern !== '') {
        members = members.filter(user => {
          return user.userStatus === 'Active' ? 
            user.displayName && user.displayName.toLowerCase().includes(this.searchPattern.toLowerCase()) ||
            user.otherMails && user.otherMails[0] && user.otherMails[0].toLowerCase().includes(this.searchPattern.toLowerCase()) ||
            user.userStatus.toLowerCase().includes(this.searchPattern.toLowerCase())
            :
            user.otherMails && user.otherMails[0] && user.otherMails[0].toLowerCase().includes(this.searchPattern.toLowerCase()) ||
            user.userStatus.toLowerCase().includes(this.searchPattern.toLowerCase());
          }
        );
      }

      // Sorting
      if (this.membersSortingColumn === 'name') {
        members.sort(this.compareMembersByName);
      } else {
        members.sort(this.compareMembersByEmail);
      }

      if (this.membersSortingOrder === 'DESC') {
        members.reverse();
      }

      return members;
    },
    currentPageGroupMembers: function() {
      // Pagination
      let startIndex = (this.membersPage - 1) * this.membersPerPage;
      let endIndex = startIndex + this.membersPerPage;

      return this.sortedFilteredGroupMembers.slice(startIndex, endIndex)
    }
  },
  mounted: async function() {
    await this.getGroupMembers();
  },
  methods: {
    getGroupMembers: async function() {
      let accessToken = await this.$msal.acquireToken();
      this.$store.commit("setToken", accessToken);

      let params = {
        groupId: this.$store.state.groupId,
      }
      
      this.groupMembersLoading = true;
      await axios.get('/GetGroupMembers', {
        params: params,
        headers: { Authorization: accessToken }
      })
      .then(response => {
        this.groupMembers = response.data;
        // console.log("groupMembers: ", this.groupMembers)
        this.membersPage = 1;
        for(let i=0; i<this.groupMembers.length + 1; i++) {
          if(this.groupMembers[i].userStatus !== 'Active') {
            // convert to string to be consistent with other group members ids, type is string
            let id = i.toString();
            this.groupMembers[i].id = id;
          }
        }
        this.groupMembersLoading = false;
        this.flagClickedID = '';
        this.flagLoading = false;
      })
      .catch(() => {
        this.groupMembersLoading = false;
        this.flagClickedID = '';
        this.flagLoading = false;
      })
    },
    searchGroupMembers: function() {
      this.membersPage = 1;
      this.searchPattern = this.searchPatternInputValue;
    },
    resetSearchGroupMembers: function() {
      this.membersPage = 1;
      this.searchPatternInputValue = '';
      this.searchPattern = '';
    },
    resetPageSortingFilteringValues: function() {
      this.registrationStatusFilter = [];
      this.searchPatternInputValue = '';
      this.searchPattern = '';
      this.membersPage = 1;
    },
    validateEmail: function(newUserEmail) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(newUserEmail) || newUserEmail == '';
    },
    validateNonSig: function(newUserNonsig) {
      const reg = new RegExp('^[0-9]+$');
      if(newUserNonsig.match(reg) !== null || newUserNonsig == '') {
        return true
      } else if(newUserNonsig.match(reg) === null || newUserNonsig == '') {
        return false
      }
    },
    compareEmails(emailsToBeAdded, currentMembers) {
      const currentMembersEmails = [];
      for(let i=0; i<currentMembers.length; i++) {
        currentMembersEmails[i] = currentMembers[i].otherMails[0];
      }
      return currentMembersEmails.includes(emailsToBeAdded);
    },
    setInvitedUsers: function(newUserEmail, newUserNonsig) {
      const emailIsValid = this.validateEmail(newUserEmail);
      const userExistsInGroup = this.compareEmails(newUserEmail, this.groupMembers);
      let emailValidated = false
      let nonsigValidated = false

      if(this.$store.state.groupNonSigFlag) {
        const nonSigIsValid = this.validateNonSig(newUserNonsig)
        // if(newUserNonsig.length === 0) {
        //   this.openAlert('emptyNonSig')
        // }
        if(!nonSigIsValid || newUserNonsig.length > 9) {
          this.openAlert('invalidNonSig')
        }
        else if(newUserNonsig.length < 9 && newUserNonsig.length !== 0) {
          let length = 9 - newUserNonsig.length
          let leadingZeroes = ''
          for(let i=0; i<length; i++) {
            leadingZeroes = leadingZeroes + '0'
          }
          newUserNonsig = leadingZeroes + newUserNonsig
          this.newUserNonsig = newUserNonsig
          nonsigValidated = true
        } else {
          nonsigValidated = true
        }

        let duplicateInvite = false
        this.invitedUsers.find(function(item) {
          if(item.email === newUserEmail) {
            duplicateInvite = true
          }
        });

        if(newUserEmail.length === 0) {
          this.openAlert('emptyEmail')
        } else if(!emailIsValid) {
          this.openAlert('invalidEmail')
        } else if(userExistsInGroup || duplicateInvite) {
          this.openAlert('duplicateEmail');
        } else {
          emailValidated = true
        }

        if(nonsigValidated && emailValidated) {
          let i = this.invitedUsers.length;
          if(newUserNonsig !== null || newUserNonsig !== '') {
            this.invitedUsers[i] = {email: newUserEmail.toLowerCase(), nonsig: newUserNonsig};
          } else {
            this.invitedUsers[i] = {email: newUserEmail.toLowerCase()};
          }
          this.$forceUpdate();
        }
      } else {
        let duplicateInvite = false
        this.invitedUsers.find(function(item) {
          if(item.email === newUserEmail) {
            duplicateInvite = true
          }
        });

        if(newUserEmail.length === 0) {
          this.openAlert('emptyEmail')
        } else if(!emailIsValid) {
          this.openAlert('invalidEmail')
        } else if(userExistsInGroup || duplicateInvite) {
          this.openAlert('duplicateEmail');
        } else {
          emailValidated = true
        }

        if(emailValidated) {
          let i = this.invitedUsers.length;
          this.invitedUsers[i] = {email: newUserEmail.toLowerCase()};
          this.$forceUpdate();
        }
      }
    },
    removeUserEmail: function(userEmail) {
      let index = 0
      this.invitedUsers.find(function(item, i) {
        if(item.email === userEmail) {
          index = i
          return i
        }
      });
      // console.log(foundUser)
      this.invitedUsers.splice(index, 1);
    },
    addNewMember: async function(invitedUsers) {
      this.addToGroupLoading = true;
      let decodedToken = jwt_decode(this.$store.state.token);
      let createdBy = decodedToken.oid;

      let data = {}
      let invitedUsersData = []
      // let nonsig = ''

      if(!this.$store.state.groupNonSigFlag) {
        for(let i=0; i<invitedUsers.length; i++) {
          invitedUsersData[i] = {otherMails: [invitedUsers[i].email]};
        }

      } else if(this.$store.state.groupNonSigFlag) {
        for(let i=0; i<invitedUsers.length; i++) {
          invitedUsersData[i] = {otherMails: [invitedUsers[i].email], nonsig: invitedUsers[i].nonsig};
          // invitedUsersNonsigs[i] = invitedUsers[i].nonsig
          // for now until addToGroupExt can handle an array of nonsigs
          // nonsig = invitedUsers[i].nonsig
        }
      }

      data = {
        users: invitedUsersData,
        appId: this.$store.state.appId,
        groupId: this.$store.state.groupId,
        CreatedBy: createdBy
        // nonsig: invitedUsersNonsigs
      }

      await axios.post('/AddToGroupExt', data,
      {
        headers: {
          Authorization: this.$store.state.token,
          'Content-Type': 'application/json'
        }
      }
      )
      .then(response => {
        this.addNewMembersLoading = true;
        const responseStatus = response.status;

        // 200 all users succeeded
        if(responseStatus == 200 || responseStatus == 204) {
          // track gtm event
          window.dataLayer.push({ 'event': 'maintainGroup', 'maintainType': 'add', 'groupName': this.$store.state.groupName });

          this.openAlert('usersAddedSuccess');

          // 206 partial success - some users failed
        } else if(responseStatus == 206) {
          // track gtm event
          window.dataLayer.push({ 'event': 'maintainGroup', 'maintainType': 'add-partial', 'groupName': this.$store.state.groupName });

          // These users have already been added or invited to this group
          this.$store.dispatch('alerts/setAlertPropValue', { propName: 'existsOrInvited', value: response.data.existsOrInvited });
          // The API failed in the process of adding these users
          this.$store.dispatch('alerts/setAlertPropValue', { propName: 'failedUsers', value: response.data.failedUsers });
          // These users had an invalid email
          this.$store.dispatch('alerts/setAlertPropValue', { propName: 'invalidEmails', value: response.data.invalidEmails });
          this.openAlert('usersAddedPartialSuccess');

          // 400 all users failed
        } else if(responseStatus == 400) {
          // console.log(error)
          this.openAlert('usersAddedError');
        }
        this.clearEmails();
        this.resetPageSortingFilteringValues();
        this.getGroupMembers();
        this.addToGroupLoading = false;
      })
      .catch(error => {
        if(error == 'Error: Request failed with status code 400') {
          // console.log(error)
          this.openAlert('usersAddedError');
        }
        this.clearEmails();
      })

      this.addNewMembersLoading = false;
      this.getGroupMembers();
    },
    openModal: function(modalType, groupMember) {
      if(modalType === 'showRemoveMembersModal') {
        this.showRemoveMembersModal = true;
      } else if(modalType === 'showResendInviteModal') {
        this.showResendInviteModal = true;
      } else if (modalType === 'showBulkInviteModal') {
        this.showBulkInviteModal = true;
      } else if(modalType === 'showFlagUserModal') {
        this.showFlagUserModal = true;
        this.flagClickedID = groupMember.id;
        this.flagClickedMember = groupMember;
      }
    },
    closeModal: function() {
      // Close Modal
      this.showModal = false

      // Reset Modal Type
      this.showInviteModal = false
      this.showRemoveMembersModal = false
      this.showResendInviteModal = false
      this.showBulkInviteModal = false
      this.showFlagUserModal = false

      // Reset Flag User Values
      this.flagClickedID = ''
      this.flagClickedName = ''
      this.flaggedComments = ''

      // Reset nonsig value
      this.newUserNonsig = ''

    },
    membersRemoveCompleted: async function(wasRemoveSuccessful) {
      // clear selected rows
      this.selectedRowsIds = [];
      this.selectedRowsEmails = [];
      this.selectedRowsStatus = [];
      this.tooltipText = 'Select All';

      if (wasRemoveSuccessful) {
        this.resetPageSortingFilteringValues();

        // track gtm event
        window.dataLayer.push({ 'event': 'maintainGroup', 'maintainType': 'remove', 'groupName': this.$store.state.groupName });
      }

      this.getGroupMembers();
      this.showRemoveMembersModal = false;
    },
    inviteResendCompleted: async function() {
      // clear selected rows
      this.selectedRowsIds = [];
      this.selectedRowsEmails = [];
      this.selectedRowsStatus = [];
      this.tooltipText = 'Select All';

      // track gtm event
      window.dataLayer.push({ 'event': 'maintainGroup', 'maintainType': 'resend invite', 'groupName': this.$store.state.groupName });

      this.showResendInviteModal = false;
    },
    bulkInviteCompleted: function() {
      // track gtm event
      window.dataLayer.push({ 'event': 'maintainGroup', 'maintainType': 'bulk invite', 'groupName': this.$store.state.groupName });

      this.getGroupMembers();
    },
    clearEmails: function() {
      this.userEmail = '';
      this.newUserEmail = '';
      this.newUserNonsig = '';
      this.invitedUsers = [];
    },
    flagUser: async function(userId, groupMember) {
      this.flagLoading = true;

      const flagErrorAlert = (type) => {
        this.openAlert(type);
        this.getGroupMembers();
        this.closeModal();
      }

      if(groupMember.displayName == null) {
        flagErrorAlert('flagPendingError');
        this.flagLoading = false;
      } else {
           // Dev environment extensions app
        if(groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged
           // Test environment extensions app
        || groupMember.extension_2cdd043be83644a49b7efef1a4fc8be6_Flagged
           // Prod environment extensions app
        || groupMember.extension_a3ba1f5147054d2a94ca8810f1b0c368_Flagged
        ) {
          this.flagClickedID = groupMember.id;
          await axios.patch('/FlagUser', {            
            flagged: false,
            comment: 'user un-flagged'
          },
          {
            params:{
              userId: userId
            },
            headers:{
              Authorization: this.$store.state.token
            }
          })
          .then(() => {
            this.getGroupMembers();
            // track gtm event
            window.dataLayer.push({ 'event': 'maintainGroup', 'maintainType': 'unflag', 'groupName': this.$store.state.groupName });
            this.closeModal();
          })
          .catch(() => {
            flagErrorAlert('flagError');
          })

                  // Dev environment extensions app
          } else if((groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged == undefined
                  || !groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged
                  || groupMember.extension_758392ae23844decaf1d7594e5f043db_Flagged == null)

                  ||
                    // Test environment extensions app
                    (groupMember.extension_2cdd043be83644a49b7efef1a4fc8be6_Flagged == undefined
                  || !groupMember.extension_2cdd043be83644a49b7efef1a4fc8be6_Flagged
                  || groupMember.extension_2cdd043be83644a49b7efef1a4fc8be6_Flagged == null)

                  ||
                    // Prod environment extensions app
                    (groupMember.extension_a3ba1f5147054d2a94ca8810f1b0c368_Flagged == undefined
                  || !groupMember.extension_a3ba1f5147054d2a94ca8810f1b0c368_Flagged
                  || groupMember.extension_a3ba1f5147054d2a94ca8810f1b0c368_Flagged == null)) {

            await axios.patch('/FlagUser', {

            flagged: true,
            comment: this.flaggedComments
          },
          {
            params:{
              userId: userId
            },
            headers:{
              Authorization: this.$store.state.token
            }
          })
          .then(() => {
            this.getGroupMembers();
            this.closeModal();
            // track gtm event
            window.dataLayer.push({ 'event': 'maintainGroup', 'maintainType': 'flag', 'groupName': this.$store.state.groupName });
          })
          .catch(() => {
            flagErrorAlert('flagError');
          })
        } else {
          flagErrorAlert('flagError');
        }
      }
    },
    selectRow: function(groupMember) {
      {
        // If group members is already selected, deselect it (splice from all selected arrays)
        if(this.selectedRowsIds.includes(groupMember.id)) {
          let i = this.selectedRowsIds.indexOf(groupMember.id);

          // Remove from selected arrays
          this.selectedRowsIds.splice(i, 1);
          this.selectedRowsEmails.splice(i, 1);
          this.selectedRowsStatus.splice(i, 1);

          // Add group member to selected arrays
        } else if (!this.selectedRowsIds.includes(groupMember.id)) {

          let i = this.selectedRowsIds.length;

          // populate selected row ids
          this.selectedRowsIds[i] = groupMember.id;

          // populate selected row emails
          this.selectedRowsEmails[i] = groupMember.otherMails[0];

          // populate selected row statuses
          this.selectedRowsStatus[i] = groupMember.userStatus;
        }
      }
      this.getGroupMembers();
    },
    selectAll: function(groupMembers) {
      // Deselect all if length of group members  == length of selected group members
      if(this.selectedRowsIds.length == groupMembers.length) {
        this.selectedRowsIds = [];
        this.selectedRowsEmails = [];
        this.selectedRowsStatus = [];
        this.tooltipText = 'Select All';

        // Select all
      } else {
        for(let i=0; i<groupMembers.length; i++) {
          if(!this.selectedRowsIds.includes(groupMembers[i].id)) {
            this.selectedRowsIds[i] = groupMembers[i].id;
            this.selectedRowsEmails[i] = groupMembers[i].otherMails[0];
            this.selectedRowsStatus[i] = groupMembers[i].userStatus;
          } else if(this.selectedRowsIds.includes(groupMembers[i].id)) {
            continue;
          }
        }
        this.tooltipText = 'Deselect All';
      }
      this.getGroupMembers();
    },
    openAlert: function(alertType) {
      this.$store.dispatch('alerts/openAlert', alertType);
    },
    changeMembersSorting: function(column) {
      if (column === this.membersSortingColumn){
        this.membersSortingOrder = this.membersSortingOrder === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.membersSortingColumn = this.membersSortingColumn === 'name' ? 'email' : 'name';
      }
    },
    closeGroup() {
      this.$store.commit("setGroupId", '');
      this.$store.commit("setGroupName", '');
      this.groupMembers = [];
      this.selectedRowsIds = [];
      this.selectedRowsEmails = [];
      this.selectedRowsStatus = [];

      this.resetPageSortingFilteringValues();

      this.invitedUsersSuccess = [];
      this.invitedUsersFail = [];
      this.addedUsersSuccess = [];
      this.addedUsersFail = [];
      this.invitedUserEmail = '';
      this.invitedUserNonsig = '';
      this.invitedUsers = [];
      this.invitedUsersLength = 0;

      this.userAddedSuccess = false;
      this.userAddedError = false;
      this.inviteSuccess = false;
      this.inviteError = false;

      this.$emit('close');
    },
    compareMembersByName(a,b) {
      if (!a.displayName) return -1;
      if (a.displayName > b.displayName) return 1;
      if (a.displayName < b.displayName) return -1;
      return 0;
    },
    compareMembersByEmail(a,b) {
      if (a.otherMails[0] > b.otherMails[0]) return 1;
      if (a.otherMails[0] < b.otherMails[0]) return -1;
      return 0;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .primaryButton {
    background-color: #0D2F42;
    color: white !important;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    transition: 0.25s;
    border: 2px #0D2F42 solid;
  }
  .primaryButton:hover {
    opacity: 0.5;
  }
  .primaryButton:focus {
    outline: none;
  }

  .secondaryButton {
    background-color: #6c757d;
    color: white !important;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    transition: 0.25s;
    border: 2px #6c757d solid;
  }
  .secondaryButton:hover {
    opacity: 0.5;
  }
  .secondaryButton:focus {
    outline: none;
  }

  .B2Cbutton-add {
    color: white;
    font-size: 14px;
    font-weight: 600;
    background-color: #0D2F42;
    border: none;
    border-radius: 18px;
    padding: 4px 14px;

    transition: 0.25s;
  }

  .B2Cbutton-add:hover {
    background-color: #8cc460;
    cursor: pointer;
  }

  .B2Cbutton-cancel {
    color: #0D2F42;
    font-size: 14px;
    font-weight: 400;
    background-color: white;
    border: 2px #0D2F42 solid;
    border-radius: 18px;
    padding: 4px 14px;

    transition: 0.25s;
  }

  .B2Cbutton-cancel:hover {
    opacity: 0.5;
  }

  .openGroup-outer {
    width: 100%;
    height: 20px;
    margin-top: 10px;
  }

  .openGroup {
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    float: right;
    margin-right: 10px;
    transition: 0.15s;
  }

  .GroupsTile:hover .openGroup {
    margin-right: 0px;
  }

  @keyframes openGroup {
    from {
      top: -100%;
    }
    to {
      top: 0%;
    }
  }

  @keyframes openGroup-overlay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .modal {
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .modalOverlay {
    background-color: rgb(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }

  .modalOverlay:hover {
    cursor: pointer;
  }

  .modal-background {
    position: absolute;
    top: 0;
    left: 50%;
    background-color: white;
    transform: translateX(-50%);
    overflow: hidden;

    animation-name: openGroup;
    animation-duration: 0.35s;
  }

  .closeBtn {
    position: absolute;
    color: #0D2F42;
    font-size: 1.2em;
    line-height: 0.75;
    top: 0;
    right: 0;
    margin-right: 20px;
    margin-top: 20px;
  }

  .closeBtn:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  .addNewMembersLoading {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .loadingMsg {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .load-wrapper {
    padding: 10px;
    display: flex;
  }

  .tableTitle-wrapper {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .tableTitle {
    font-size: 20px;
    color: #0D2F42;
    text-align: center;
  }

  .table-header-logo-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100px;
    overflow: hidden;
  }

  .tableRow-wrapper {
    display: table-row-group;
  }

  .tableRow:nth-child(odd) {
    background-color: rgba(13, 47, 66, 0.15);
  } 

  .tableRow:nth-child(even) {
    background-color: white;
  }

  .tableRow {
    border-bottom: 1.5px solid #0D2F42;
    transition: 0.15s;
  }

  .tableRow:hover {
    background-color: rgba(13, 47, 66, 0.32);
    cursor:default;
  }

  .numUsers {
    float: right;
    padding: 5px 0px 5px 5px;
  }

  .tableHeader {
    background-color: #0D2F42;
    color: white;
    margin-bottom: 20px;
  }

  .tableHeader-title {
    margin-left: 15px;
    margin-right: 15px;
  }

  .tableHeader-column1, .tableHeader-column2, .tableHeader-column3, .tableHeader-column4,  .tableHeader-column5,  .tableHeader-column6 {
    padding: 5px;
    border-right: 2px solid;
    border-color: rgba(255, 255, 255, 0.5);
  }

  .tableHeader-column1 {
    border-radius: 50px 0 0 50px;
  }

  .tableHeader-column1, .tableHeader-column2 {
    cursor: pointer;
  }

  .tableHeader-column5, .tableHeader-column6 {
    border-right: 0;
  }

  .tableHeader-column6 {
    border-radius: 0 50px 50px 0;
    width: 50px;
  }

  .tableData {
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px 0px 15px 15px;
  }

  .tableDataActive {
    text-align: center;
    font-weight: 600;
    color: #1b8301;
    background-color: #c7ffb9;
    border-radius: 30px;
    margin-left: 15px;
  }

  .tableDataPending {
    text-align: center;
    font-weight: 600;
    color: #867200;
    background-color: #ffec82;
    border-radius: 30px;
    margin-left: 15px;
  }

  .togglePanel {
    background-color: #8cc460;
    color: white;
    font-size: 14px !important;
    font-weight: 600;
    border-radius: 50px;
    transition: 0.25s;
  }

  .togglePanel:hover {
    opacity: 0.5;
    color: white;
  }

  .togglePanel:focus {
    color: white;
    outline: none;
  }

  .bulkInviteBtn {
    margin-left: 20px;
    font-size: 14px !important;
  }

  .openInviteModal {
    background-color: #8cc460;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    transition: 0.25s;
    padding: 8px 22px;
    margin-left: 20px;
  }

  .openInviteModal:hover {
    opacity: 0.5;
    color: white;
  }

  .openInviteModal:focus {
    color: white;
  }

  .delete-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    transition: 0.2s;
    max-width: 125px;
    height: 76px;
  }

  .delete-wrapper:hover {
    cursor: pointer;
  }
  .delete-wrapper:hover .action-icon {
    color: rgb(253, 88, 88);
  }

  .resend-invite-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    transition: 0.2s;
    max-width: 150px;
    text-overflow: ellipsis;
    height: 76px;
  }
  .resend-invite-wrapper:hover {
    cursor: pointer;
  }
  .resend-invite-wrapper:hover .action-icon {
    color: #0d2f425e;
  }

  .add-members-wrapper {
    padding: 38px 10px 10px 10px;
  }

  .additional-action-wrapper {
    display: flex;
    width: 100%;
    padding: 0px 20px 10px 10px;
    justify-content: end;
  }

  .resend-invite-btn, .delete-btn {
    position: absolute;
    bottom: 0;
  }

  .action-label {
    visibility: visible;
    color: #0D2F42 !important;
    font-size: 12px !important;
    text-transform: uppercase;
    padding-bottom: 5px;
    margin-bottom: -25px;
    height: auto;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
  }

  .action-label-hidden {
    visibility: hidden;
    padding-bottom: 0px;
    margin-bottom: -30px;
    height: 0px;
  }

  .action-icon {
    color: #0D2F42;
    transition: 0.25s;
  }

  .action-icon-disabled {
    color: #0d2f425e;
    transition: 0.25s;
  }

  .mainSelectionCircle {
    color: white;
    font-size: 1.5em;
    font-weight: 400;
    text-align: center;
    line-height: 0.65;
    width: 30px;
    height: 30px;
    border: 2px white solid;
    border-radius: 20px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    transition: 0.2s;
    float: right;
  }
  .mainSelectionCircle:hover {
    color: #0D2F42;
    background-color: white;
    cursor: pointer;
  }

  .mainSelectionCircle-selected {
    background-color: white;
  }

  ::v-deep .tooltip, .b-tooltip, .bs-tooltip-top {
    font-size: 14px !important;;
  }

  ::v-deep .tooltip::after, .b-tooltip::after, .bs-tooltip-top::after {
    font-size: 14px !important;;
  }

  .selectionCircle {
    color: white;
    font-size: 1.5em;
    font-weight: 400;
    text-align: center;
    line-height: 0.65;
    width: 25px;
    height: 25px;
    border: 2px #0D2F42 solid;
    border-radius: 20px;
    margin-top: 20px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    transition: 0.2s;
    float: right;
  }

  .selectionCircle:hover {
    background-color: #0D2F42;
  }

  .selectionCircle-selected {
    background-color: #0D2F42;
  }

  .check-icon {
    padding-bottom: 3;
  }

  .userRow-selected {
    background-color: #93c5ff !important;
  }

  .removeMember {
    background-color: rgb(253, 88, 88);
    color: white;
    font-size: 1.5em;
    font-weight: 400;
    text-align: center;
    line-height: 0.65;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 6px;
  }

  .removeMember:hover {
    background-color: rgb(130, 20, 20);
  }

  .sidebar-wrapper {
    float: right;
    z-index: 100;
  }

  .sidebar {
    background-color: white;
  }

  .sidebar-inner {
    padding-left: 16px;
    padding-right: 16px;
  }

  ::v-deep .b-sidebar.b-sidebar-right {
    background-color: #0D2F42;
    color: white;
    padding: 30px;
    height: 100%;
    width: 500px;
    bottom: 0;
    margin-top: auto;
    border-radius: 30px 0 0 0;
  }

  ::v-deep .b-sidebar.b-sidebar-right > .b-sidebar-header .close {
    color: white;
    font-size: 2em;
  }

  ::v-deep .b-sidebar.b-sidebar-right ::-webkit-scrollbar {
    height: auto;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    width: 15px;
  }

  ::v-deep .b-sidebar.b-sidebar-right ::-webkit-scrollbar-thumb {
    height: auto;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 10px;
  }

  ::v-deep .b-sidebar.b-sidebar-right ::-webkit-scrollbar-corner {
    background:#0D2F42;
  }

  .spacer {
    height: 20px;
    border-top: none;
  }

  .addInvitedUser {
    width: 100%;
    border: 1px solid #004EA8;
    float: left;
    text-transform: lowercase !important;
  }

  .addEmailFeild-wrapper {
    width: 100%;
  }

  .addInvitedUserBtn-wrapper {
    width: 40px;
    float: right;
    margin-top: 10px;
  }

  .addEmailBtn {
    height: 32px;
    width: 120px;
    border-radius: 5px;
    background-color: #6bbdff;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    float: right;
    padding-bottom: 5px;
  }

  .addEmailBtn:hover {
    opacity: 0.75;
  }

  .addEmailBtn:disabled {
    color: #D4D4D4;
    background-color: #4D556A;
  }
  .addEmailBtn:disabled:hover {
    color: #D4D4D4;
    background-color: #4D556A;
    opacity: 1;
    cursor: not-allowed;
  }

  .buttonsWrapper {
    margin-top: 20px;
  }

  .addMembers, .cancelBtn {
    font-weight: 600;
    border: none;
    border-radius: 40px;
    padding: 3px 10px;
  }

  .addMembers {
    color: white;
    background-color: rgb(115, 221, 119);
    align-self: end;
  }

  .edit-msg {
    margin-top: 15px;
  }

  .cancelBtn {
    background-color: rgb(255, 255, 255);
    color: #0D2F42;
  }

  .addMembers-wrapper {
    float: right;
  }

  .cancelBtn-wrapper {
    float: right;
    margin-right: 10px;
  }

  .nameIcon {
    width: 28px;
    height: 28px;
    border-radius: 20px;
    background-color: rgb(124, 207, 235);
    float: left;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
  }

  .newUserEmail {
    height: 35px;
    width: 100%;
    margin-top: 20px;
    /* text-align: end; */
    font-size: 1em;
    font-weight: 700;
    color: #0D2F42;
    background-color: white;
    padding: 5px 14px;
    border-radius: 20px;
  }

  .newUserEmail-inner {
    /* width: 225px;
    max-width: 225px; */
    overflow: hidden;
    text-overflow: ellipsis;
    /* float: left; */
    padding: 5px 10px;
    border-right: #0D2F42 solid 2px;
  }

  .newUserNonsig-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 10px;
  }

  .removeUserEmailBtn {
    background-color: #ff5656;
    color: white;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    border: none;
    height: 22px;
    width: 22px;
    margin-top: 2px;
    margin-right: 5px;
    border-radius: 30px;
    float: right;
  }

  .home-left {
    float: left;
    height: 100%;
  }

  .footer {
    position: fixed;
    bottom: 10px;
    width: calc(75% - 30px);
    background-color: #fff;
  }

  .hide-small {
    display: block;
  }

    .collapseBtn-wrapper {
      transition: 0.25s;
      padding-top: 20px;
      width: 48px;
      height: 68px;
      margin-left: 8px;
      /* background-color: tomato; */
    }

    .collapseBtn-collapsed-wrapper {
      transition: 0.25s;
      margin-left: 10px;
      padding-top: 20px;
      width: 48px;
      height: 68px;
    }

    .collapseBtn-wrapper:hover {
      cursor: pointer;
      transform: translateX(-3px) !important;
    }

    .collapseBtn-collapsed-wrapper:hover {
      cursor: pointer;
      transform: translateX(3px);
    }

    .collapseBtn {
      color: #0D2F42;
      font-size: 0.8em;
      line-height: 1.4em;
      width: 20px;
      height: 20px;
      background-color: rgba(255,255,255,0.75);
      border-radius: 30px;
      text-align: center;
      transition: 0.25s;
      margin: 10px;
    }

    .collapseBtn-collapsed {
      color: #0D2F42;
      font-size: 0.8em;
      line-height: 1.4em;
      width: 20px;
      height: 20px;
      background-color: rgba(255,255,255,0.75);
      border-radius: 30px;
      text-align: center;
      margin: 10px;
    }

    .collapseBtn::after {
      content: "🡠";
    }

    .collapseBtn-collapsed::after {
      content: "🡢";
    }

    .inviteModal {
      position: absolute;
      background-color: white;
      width: 85%;
      min-width: 300px;
      height: 90%;
      z-index: 100;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .userRow-flagged {
      background-color: #ffe6e6 !important;
    }

    .userRow-flagged:hover {
      background-color: #f0c5c5 !important;
    }

    .flag-icon {
      transition: 0.25s;
      color: #0D2F42;
      margin-right: 10px;
      font-size: 20px !important;
    }
    .flag-icon:hover {
      /* opacity: 0.5; */
      cursor: pointer;
      color: #e63333;
    }

    .flag-icon-unflagged {
      color: #e63333;
      transition: 0.25s;
    }

    .flag-icon-unflagged:hover {
      opacity: 0.5;
      cursor: pointer;
    }

    .flagLoading-overlay {
      /* position: absolute; */
      background-color: #0d2f4242;
      background-size: cover;
    }

    .flagLoading-icon {
      /* position: absolute; */
      margin: auto;
    }

    .groups-header-wrapper {
      margin-bottom: 50px;
    }

    .groups-header {
      margin-left: 15px;
      padding-top: 25px;
    }

    .line-wrapper {
      position: absolute;
      /* width: calc(100% - 295.719px); */
      width: calc(100% - 30.719px);
      margin-left: 15px;
    }

    .line-connector-top {
      float: left;
      border-top: #0D2F42 solid 2px;
      width: 220px;
    }
    .line-connector-bottom {
      position: relative;
      border-bottom: #0D2F42 solid 2px;
      height: 50px;
      margin-left: 280px;
    }

    .curve-line {
      float: left;
      height: 50px;
    }

    .sort-order-transition {
      transition: transform 0.4s ease-in-out;
    }

    ::v-deep .members-status-dropdown {
      border: solid 0px !important;
      background-color: transparent !important;
      color: inherit !important;
      font: inherit !important;
    }

    ::v-deep .members-status-dropdown:hover {
      color: inherit !important;
      font: inherit !important;
    }

    ::v-deep .tableHeader .custom-control-label {
      color: #000;
      font-size: 18px;
      margin-left: 12px;
      font-weight: normal;
    }

    .search-input-group {
    max-width: 388px;
    padding-right: 10px;
    }

    .search-input {
      height: 36px;
    }

    .search-wrapper{
      padding: 39px 0px 0px 0px;
    }

    .search-btn {
      font-size: 14px !important;
    }

    .members-pagination-wrapper {
      position: absolute;
      width: 90%;
      margin: auto;
      bottom: 0px;
      height: 76px;
    }

    ::v-deep .page-link {
      background-color: #ddd;
      border-radius: 50%;
      border: 0px;
      width: 38px;
      height: 38px;
      color: #000;
      font-weight: 550;
      margin: 0px 4px 0px 4px;
    }

    ::v-deep li.active > .page-link {
      color: #fff;
      background-color: #0D2F42;
    }

    ::v-deep .prev-next-page-link {
      background-color: unset;
      border: 0px;
      border-radius: 50% !important;
      width: 38px;
      height: 38px;
    }

    ::v-deep .prev-next-page-icon {
      top: 2px;
      left: -1px;
      color: #0D2F42;
    }

    ::v-deep li.disabled .prev-next-page-icon {
      color: #ddd;
    }

    .members-pagination {
      text-align: center;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    .members-per-page {
      text-align: center;
      display: flex;
      justify-content: right;
      align-items: center;
      height: 76px;
      font-size: 16px;
    }

    .rows-per-page-label {
      padding-right: 10px;
    }

    .current-page-range {
      padding-left: 20px;
    }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 767px) {

    .newUserEmail-inner {
      max-width: 150px;
    }

    .hide-small {
      display: none;
    }

    .label {
      color: white;
      font-size: 12px !important;
      font-weight: 600;
      text-transform: uppercase;
    }

    .label-blue {
      color: #0D2F42 !important;
      font-size: 12px !important;
      font-weight: 600;
      text-transform: uppercase;
    }

    .header-wrapper {
      width: 100%;
      height: 60px;
      margin: auto;
      margin-top: 0px;
    }

    .modal-background {
      position: absolute;
      top: 0;
      left: 50%;
      background-color: white;
      width: 100%;
      height: 100%;
      transform: translateX(-50%);
      overflow: hidden;

      animation-name: openGroup;
      animation-duration: 0.35s;
    }

    .modal-inner {
      padding: 20px;
    }

    .togglePanel {
      background-color: #8cc460;
      color: white;
      font-size: 12px !important;
      font-weight: 600;
      border-radius: 50px;
      transition: 0.25s;
      padding: 8px 10px;
    }

    .bulkInviteBtn {
      font-size: 12px !important;
    }

    .table-wrapper {
      position: absolute;
      float: left;
      width: calc(100% - 10px);
      overflow: scroll;
      height: calc(100% - 450px);
    }

    .tableHeader-title {
      font-size: 12px;
    }

    .tableData {
      font-size: 12px;
    }

    .tableDataActive {
      width: 60px;
      font-size: 10px;
      text-align: center;
      font-weight: 600;
      border-radius: 30px;
      padding: 4px 0px;
    }

    .tableDataPending {
      width: 90px;
      font-size: 10px;
      text-align: center;
      font-weight: 600;
      border-radius: 30px;
      padding: 4px 0px;
    }

    .groups-header-wrapper {
      margin-top: 120px;
    }

    .footer {
      width: 100%;
      margin: auto;
      margin-left: -15px;
      bottom: 25px;
    }

    .groupMembersLoading {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: auto;
      bottom: 0px;
      text-align: center;
    }
    
    .search-input {
      height: 33.14px;
    }

    .search-wrapper{
      padding: 40px 0px 0px 10px;
    }

    .search-btn {
      font-size: 12px !important;
      padding: 6px 6px
    }

    .actions-wrapper {
      margin-bottom: 10px;
    }

    .members-pagination-wrapper {
      height: 100px;
    }

    .members-pagination {
      padding: 0px;
      justify-content: center;
    }

    .members-per-page {
      justify-content: center;
      height: 38px;
      padding: 0px;
    }

    .add-members-wrapper {
      padding: 25px 10px 0px 10px;
    }

    ::v-deep .page-link {
      width: 30px;
      height: 30px;
      font-size: 14px;
      margin: 0px 2px 0px 2px;
    }

    ::v-deep ul.pagination {
      height: 30px;
      margin: 10px 0px;
    }

    ::v-deep .prev-next-page-link {
      width: 30px;
      height: 30px;
    }

    ::v-deep .prev-next-page-icon {
      top: -1px;
      left: -5px;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {

    .newUserEmail-inner {
      max-width: 250px;
    }


    .hide-small {
      display: block;
    }

    .modal-background {
      position: absolute;
      top: 0;
      left: 50%;
      background-color: white;
      width: 90%;
      height: 95%;
      border-radius: 0 0 50px 50px;
      transform: translateX(-50%);
      overflow: hidden;

      animation-name: openGroup;
      animation-duration: 0.35s;
    }

    .modal-inner {
      padding: 40px;
    }

    .togglePanel {
      background-color: #8cc460;
      color: white;
      font-size: 16px;
      font-weight: 600;
      border-radius: 50px;
      transition: 0.25s;
      padding: 8px 12px;
    }

    .label {
      color: white;
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;
    }

    .table-wrapper {
      position: absolute;
      float: left;
      width: calc(100% - 80px);
      overflow: scroll;
      height: calc(100% - 430px);
      padding: 0px 10px;
      margin-top: 10px;
    }

    .tableHeader-title {
      font-size: 18px;
    }

    .tableData {
      font-size: 18px;
    }

    .tableDataActive {
      width: 85px;
      font-size: 16px;
      text-align: center;
      margin: 0px 0px 15px 15px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-weight: 600;
      border-radius: 30px;
    }

    .tableDataPending {
      width: 120px;
      font-size: 16px;
      text-align: center;
      margin: 0px 0px 15px 15px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-weight: 600;
      border-radius: 30px;
    }

    .groupMembersLoading {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: auto;
      bottom: 0px;
      padding-bottom: 0px;
      text-align: center;
    }

    .footer {
      bottom: 10px;
    }

    .bulkInviteBtn {
      margin-left: 10px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .hide-small {
      display: block;
    }

    .modal-background {
      position: absolute;
      top: 0;
      left: 50%;
      background-color: white;
      width: 90%;
      height: 95%;
      border-radius: 0 0 50px 50px;
      transform: translateX(-50%);
      overflow: hidden;

      animation-name: openGroup;
      animation-duration: 0.35s;
    }

    .table-wrapper {
      height: calc(100% - 360px);
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .hide-small {
      display: block;
    }

    .modal-background {
      position: absolute;
      top: 0;
      left: 50%;
      background-color: white;
      width: 90%;
      height: 95%;
      border-radius: 0 0 50px 50px;
      transform: translateX(-50%);
      overflow: hidden;

      animation-name: openGroup;
      animation-duration: 0.35s;
    }

    .bulkInviteBtn {
      margin-left: 20px;
    }
  }

  @media only screen and (max-width: 992px) {
    .search-wrapper {
      padding-left: 10px;
    }
  }
  
</style>
