<template>
  <div class="error-404">
      <b-container>
        <b-row class="error-404-wrapper">
          <b-col sm="12">
            <h1 style="margin-bottom: 20px;">Sorry about that - we couldn't find the page you're looking for.</h1>
            <p style="margin-bottom: 20px;">You may have clicked a link to a page that no longer exists, or it's possible there was a typo in the URL.</p>
            <a class="btn gy-primary backHome" href="/">Visit Homepage</a>
          </b-col>
        </b-row>
      </b-container>
      <div class="background-image"></div>
  </div>
</template>

<script>
export default {
  name: 'error-404'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  color: #0D2F42;
  font-family: 'Barlow', sans-serif;
}

.error-404-wrapper {
  background-color: white;
  border-radius: 30px;
  max-width: 600px;
  margin: auto;
  padding: 30px;
}

.error-404 {
  color: #0D2F42;
  min-height: 500px;
  padding-top: 50px;
  margin-bottom: 25px;
  text-align: center;
}

h1 {
  font-family: 'Barlow', Arial, sans-serif;
  font-weight: bold;
  font-size: 24px;
}

.backHome {
  font-size: 16px;
  font-weight: 600;
  background-color: #0D2F42;
  border: #0D2F42 2px solid;
  color: white;
  border-radius: 20px;
  padding: 4px 14px;
  transition: 0.20s;
}

a:hover {
  color: white;
}

.background-image {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/B2C_GradientBackground.png');
  background-size: cover;
  background-position: center;
  top: 0;
  z-index: -1;
}
</style>
