<template>
  <Modal @close="close">
    <h3>Flag User</h3>
    <div style="height: 20px;"></div>
    <label class="label-blue" for="flaggedComments" style="font-size: 12px !important;">*Justification for flagging user</label>
    <b-form-textarea
      id="flaggedComments"
      v-model="flaggedComments"
      placeholder="Justification"
      rows="4"
      no-resize>
    </b-form-textarea>
    <template v-slot:buttons>
      <button class="modal-button-secondary" style="margin-right: 15px;" v-on:click="close">Cancel</button>
      <button class="modal-button" :disabled="flaggedCommentsEmpty" v-on:click="flagUserSubmitted">Flag Member</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";

export default {
  name: "FlagUserModal",
  components: {
    Modal,
  },
  data: function () {
    return {
      flaggedComments: ''
    }
  },
  computed: {
    flaggedCommentsEmpty: function() {
      return this.flaggedComments === '';
    },
  },
  methods: {
    flagUserSubmitted: async function() {
      this.$emit('flag-user-submitted', this.flaggedComments)
    },
    close: function() {
      this.$emit('close');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 767px) {
    .label-blue {
      color: #0D2F42 !important;
      font-size: 12px !important;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

</style>
