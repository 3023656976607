<template>
  <div :class="alertClass">
    <div class="alert-close" v-on:click="closeAlert()">&#10006;</div>
    <b-icon v-if="variant === 'success'" icon="check-circle" animation="fade" font-scale="1.25"></b-icon>
    <b-icon v-if="variant === 'error'" icon="exclamation-circle" animation="fade" font-scale="1.25"></b-icon>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "Alert",
  props: ["variant"],
  computed: {
    alertClass: function() {
      if (this.variant === 'success')
        return 'success-alert';
      if (this.variant === 'error')
        return 'error-alert';
      //default
      return 'success-alert';
    },
  },
  methods: {
    closeAlert() {
        this.$emit('close');
    },
  },       
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .alert-close {
    text-align: right;
  }

  .alert-close:hover {
    cursor: pointer;
  }

  .success-alert {
    max-width: 300px;
    max-height: 300px;
    overflow-y: scroll;
    color: #406426;
    background-color: #cae3c3;
    z-index: 1000;
    margin: 20px 20px 0px 0px;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
  }
  
  .error-alert {
    max-width: 300px;
    max-height: 300px;
    overflow-y: scroll;
    color: #C50202;
    background-color: #FDD8D8;
    margin: 20px 20px 0px 0px;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
  }
  
</style>
