<template>
  <div class="home" role="main">

    <GroupMembers v-if="showGroupMembers" @close="closeGroup()" />

    <b-container fluid class="p-0 d-flex" style="height: 100%;" v-if="$msal.isAuthenticated()">
      <b-row class="m-0 d-flex justify-content-center">
        <div class="header-wrapper">
          <div class="logo"><img src="../assets/GYPass_Logo_Blue.png" width="200px" alt="Goodyear Pass logo" /></div>
          <div class="vr"></div>
          <div class="header-title">Admin Portal</div>
        </div>
      </b-row>

      <b-row class="body-wrapper m-0 p-0">

        <Sidebar :collapsed="isCollapsed" />

        <b-col class="groupsSection-wrapper" xs="9" sm="9" md="9" lg="9" xl="9">
          <b-row>
            <b-col class="groups-header-wrapper">
              <div class="line-wrapper">
                <div class="line-connector-top"></div>
                <img src="../assets/curve-line.svg" class="curve-line" alt="curved live"/>
                <div class="line-connector-bottom"></div>
              </div>
              <h3 class="groups-header">Your Applications</h3>
            </b-col>
          </b-row>
          <b-row v-if="usergroups.length>0" class="groupsContainer-wrapper">

            <b-row class="groupsRow-wrapper">
              <b-col cols="12" sm="12" md="6" lg="3" xl="3" v-for="usergroup in usergroups" v-bind:key="usergroup.id">

                <div class="GroupsTile" v-on:click="openGroup(usergroup.usergroups[0].id, usergroup.name, usergroup.logo, usergroup.id, usergroup.nonsig)">
                  <div class="GroupsTile-logo-wrapper m-0">
                    <img class="GroupsTile-logo" :src="usergroup.logo" :alt="usergroup.name + ' logo'" v-if="usergroup.logo" />
                    <img class="GroupsTile-logo" src="../assets/images/Wingfoot_0D2F42.svg" alt="Goodyear Wingfoot Logo" v-if="!usergroup.logo" />
                  </div>
                  <div class="GroupsTile-inner d-flex">
                    <div class="displayName">
                      <div class="displayName-inner">
                        {{ usergroup.name }}
                      </div>
                    </div>
                    <div class="b2c-app-description">
                      <div class="b2c-app-description-inner"> 
                        {{ usergroup.description }}
                      </div>
                    </div>
                    <div class="openGroup-outer mt-auto">
                      <div class="openGroup">Manage App &#x27F6;</div>
                    </div>
                  </div>
                </div>
                
              </b-col>
            </b-row>
          </b-row>

          <b-row class="noGroupsMsg-wrapper loading" v-if="groupsLoading">
             <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="1.5"></b-icon>
            <div class="noGroupsMsg">Loading...</div>
          </b-row>
          <b-row class="noGroupsMsg-wrapper" v-if="usergroups.length==0 && !groupsLoading">
            <b-icon icon="exclamation-circle" animation="fade" font-scale="1.25"></b-icon>
            <div class="noGroupsMsg">You do not manage any applications.</div>
          </b-row>

          <b-row class="footer p-0 m-0"><Footer /></b-row>
        </b-col>

      </b-row>
    </b-container>

    <div class="gradient-overlay"></div>
    <div class="background-image"></div>
  </div>
</template>
<script>
import axios from 'axios';
import { apiPath } from '../authConfig';
axios.defaults.baseURL = apiPath;
import jwt_decode from "jwt-decode";
import Footer from '../components/Footer';
import GroupMembers from '../components/GroupMembers';
import Sidebar from '../components/Sidebar';
window.dataLayer = window.dataLayer || [];

import { ApplicationInsights } from '@microsoft/applicationinsights-web'

export default {
  name: 'Groups',
  components: {
    Footer,
    GroupMembers,
    Sidebar,
  },
  data () {
    return {
      usergroups:[],
      usergroup:[],
      showGroupMembers:false,
      isCollapsed:false,
      renderComponent:true,
      groupsLoading:true,
    }
  },
  async mounted() {
    if (!this.$msal.isAuthenticated()) {
      try{
        return this.$msal.signIn();
      }
      catch(error){
        console.log(error);
      }
    }
    let accessToken = await this.$msal.acquireToken();
    // console.log(accessToken)
    this.$store.commit("setToken", accessToken);

    this.$store.dispatch('updateExtensionsApp');

    // App insights
    this.$store.dispatch('updateAppInsightsConnString');
    const appInsights = new ApplicationInsights({ config: {
      connectionString: this.$store.state.appInsightsConnString,
      enableAutoRouteTracking: true,
    }});
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    let decodedToken = jwt_decode(accessToken);
    
    this.getUserGroups(this.$store.state.token);

    let displayName = decodedToken.name;
    let currentUserEmail = decodedToken.emails[0];

    this.$store.commit("setUserDisplayName", displayName);
    this.$store.commit("setcurrentUserEmail", currentUserEmail);

    let firstInit = decodedToken.name.split(' ')[0].charAt(0);
    let lastInit = decodedToken.name.split(' ')[1].charAt(0);

    this.iconText = firstInit + lastInit;
    this.$store.commit("setUserInitials", this.iconText.toUpperCase());

    this.$store.commit("changeAuth", this.$msal);
  },
  methods : {
    getUserGroups: async function(token) {

      let decodedToken = jwt_decode(token);
      let userId = decodedToken.oid;
      this.groupsLoading = true;

      axios.get('/GetAdminGroups', {
        params: { userId: userId },
        headers:{ Authorization:token }
      })
        .then(response => {
          if(response.data.length==0) {
            this.usergroups = [];
            this.$store.commit("setUserGroups", this.usergroups)
            this.groupsLoading = false;
          } else {
            for(let i=0; i<response.data.length; i++) {
              this.usergroups[i] = response.data[i].resources[0];
            }
            this.groupsLoading = false;
          }
        })
        .catch( () => {
          this.usergroups = [];
          this.groupsLoading = false;
        })
        this.$forceUpdate();
        // console.log(this.usergroups);
    },
    openGroup(groupId, groupName, groupLogo, appId, groupNonSigFlag) {

      this.$store.commit("setGroupId", groupId);
      this.$store.commit("setGroupName", groupName);
      this.$store.commit("setGroupLogo", groupLogo);
      this.$store.commit("setGroupNonSigFlag", groupNonSigFlag)

      this.showGroupMembers = true;
      
      this.$store.commit("setAppId", appId);
      
      // track gtm event
      window.dataLayer.push({ 'event': 'openGroup', 'groupName': groupName });
    },
    closeGroup() {
      this.showGroupMembers = false;
    },
    collapseSidebar: function() {
      this.userEmail = '';

      this.invitedUsersSuccess = [];
      this.invitedUsersFail = [];
      this.addedUsersSuccess = [];
      this.addedUsersFail = [];

      this.isCollapsed = !this.isCollapsed;
    }
  }
}

</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Barlow', sans-serif;
}

h1 {
  color: #0D2F42;
}

h2 {
  color: #0D2F42;
}

h3 {
  color: #0D2F42;
}

h4 {
  color: #0D2F42;
}

.formFileInput-wrapper {
  padding: 50px;
  border: dashed rgb(168, 168, 168) 2px;
  border-radius: 10px;
  min-width: 350px;
}

.login-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}

.p-0 {
  padding-left: 0;
  padding-right: 0;
}

.m-0 {
  margin-left: 0;
  margin-right: 0;
}

.home {
  height: calc(100% - 52px);
}

.body-wrapper {
  height: calc(100% - 100px);
}

.groupsSection-wrapper {
  height: 100%;
  margin-top: -20px;
}

.header-wrapper {
  width: 495px;
  height: 60px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.logo {
  width: 200px;
  float: left;
  margin-right: 20px;
  margin-top: 5px;
}

.vr {
  display: block;
  height: 60px;
  width: 1px;
  background-color: #0D2F42;
  float: left;
  margin-right: 20px;
}

.header-title {
  display: block;
  color: #0D2F42;
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: 200;
  line-height: 2.4em;
  letter-spacing: 0.35em;
}

.gradient-overlay {
  z-index: -1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  display: block;
  /* background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); */
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 70%, rgba(255,255,255,0) 100%);
  height: 100%;
  width: 100%;
}

.background-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
  background-image: url('../assets/images/B2C_Background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
}

.b2c-container {
  padding: 0px 80px;
}

.header {
  width: 100%;
  height: 52px;
  background-color: #004EA8;
}

.logo-wrapper {
  margin-top: 10px;
}

.header-logo {
  margin-left: 20px;
}

.B2Cbutton {
  color: white;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #004EA8;
  border: none;
  border-radius: 18px;
  padding: 4px 14px;
}

.B2Cbutton:hover {
  opacity: 0.5;
}

.groupsContainer-wrapper {
  height: calc(100% - 132px);
  overflow-y: scroll;
  overflow-x: hidden;
}

::v-deep ::-webkit-scrollbar {
  height: auto;
  background: #0d2f4228;
  border-radius: 10px;
  width: 15px;
}

::v-deep ::-webkit-scrollbar-thumb {
  height: auto;
  background: #0d2f42;
  border-radius: 10px;
}

.groupsRow-wrapper {
  margin-left: 0px;
  height: auto;
}
.groupsRow-wrapper:last-child {
  margin-right: 0px;
}

.GroupsTile {
  border: 3px solid #0D2F42;
  border-radius: 30px;
  color: #0D2F42;
  max-width: 330px;
  width: 100%;
  min-height: 100px;
  height: 250px;
  margin-bottom: 30px;
  transition: 0.15s;
}

.GroupsTile:hover {
  background-color: #0D2F42;
  border: 3px solid #0D2F42;
  color: white;
  cursor: pointer;
}

.GroupsTile:hover .GroupsTile-logo {
  filter: brightness(0) invert(1);
}

.GroupsTile-logo-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  overflow: hidden;
}

.GroupsTile-logo-wrapper-inner {
  width: fit-content;
  height: fit-content;
  max-width: 100px;
}

.GroupsTile-inner {
  margin-top: 10px;
  width: 100%;
  height: calc(100% - 70px);
  padding: 0px 15px;
}

.displayName {
  width: 100%;
  height: 45px;
}

.displayName-inner {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.b2c-app-description {
  position: relative;
  max-width: 310px;
  width: 100%;
  height: 80px;
  margin-top: 10px;
  padding-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.b2c-app-description-inner {
  font-size: 14px;
  font-weight: 500;
  max-width: 310px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closeModal {
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: 200;
}

.openGroup-outer {
    width: 100%;
    height: 20px;
    margin-top: 10px;
  }

  .openGroup {
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    float: right;
    margin-right: 10px;
    transition: 0.15s;
  }

  .GroupsTile:hover .openGroup {
    margin-right: 0px;
  }

  @keyframes openGroup {
    from {
      top: -100%;
    }
    to {
      top: 0%;
    }
  }

  @keyframes openGroup-overlay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.sidebar-wrapper {
  float: right;
  z-index: 100;
}

.sidebar {
  background-color: white;
}

.sidebar-inner {
  padding-left: 16px;
  padding-right: 16px;
}

::v-deep .b-sidebar.b-sidebar-right {
  background-color: #0D2F42;
  color: white;
  padding: 30px;
  height: 100%;
  width: 500px;
  bottom: 0;
  margin-top: auto;
  border-radius: 30px 0 0 0;
}

::v-deep .b-sidebar.b-sidebar-right > .b-sidebar-header .close {
  color: white;
  font-size: 2em;
}

::v-deep .b-sidebar.b-sidebar-right ::-webkit-scrollbar {
  height: auto;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  width: 15px;
}

::v-deep .b-sidebar.b-sidebar-right ::-webkit-scrollbar-thumb {
  height: auto;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
}

::v-deep .b-sidebar.b-sidebar-right ::-webkit-scrollbar-corner {
  background:#0D2F42;
}

.home-left {
  float: left;
  height: 100%;
}

.footer {
  position: fixed;
  bottom: 10px;
  width: calc(75% - 30px);
  background-color: #fff;
}

  .collapseBtn-wrapper {
    transition: 0.25s;
    padding-top: 20px;
    width: 48px;
    height: 68px;
    margin-left: 8px;
    /* background-color: tomato; */
  }

  .collapseBtn-collapsed-wrapper {
    transition: 0.25s;
    margin-left: 10px;
    padding-top: 20px;
    width: 48px;
    height: 68px;
  }

  .collapseBtn-wrapper:hover {
    cursor: pointer;
    transform: translateX(-3px) !important;
  }

  .collapseBtn-collapsed-wrapper:hover {
    cursor: pointer;
    transform: translateX(3px);
  }

  .collapseBtn {
    color: #0D2F42;
    font-size: 0.8em;
    line-height: 1.4em;
    width: 20px;
    height: 20px;
    background-color: rgba(255,255,255,0.75);
    border-radius: 30px;
    text-align: center;
    transition: 0.25s;
    margin: 10px;
  }

  .collapseBtn-collapsed {
    color: #0D2F42;
    font-size: 0.8em;
    line-height: 1.4em;
    width: 20px;
    height: 20px;
    background-color: rgba(255,255,255,0.75);
    border-radius: 30px;
    text-align: center;
    margin: 10px;
  }

  .collapseBtn::after {
    content: "🡠";
  }

  .collapseBtn-collapsed::after {
    content: "🡢";
  }

  .noGroupsMsg-wrapper {
    display: flex;
    padding-left: 16px;
  }

  /* .loading {
    margin-top: 25px;
  } */

  .noGroupsMsg {
    font-size: 26px;
    line-height: 20px;
    font-weight: 600;
    padding-left: 10px;
    color: #0D2F42;
  }

  .groups-header-wrapper {
    margin-bottom: 50px;
  }

  .groups-header {
    margin-left: 15px;
    padding-top: 25px;
  }

  .line-wrapper {
    position: absolute;
    /* width: calc(100% - 295.719px); */
    width: calc(100% - 30.719px);
    margin-left: 15px;
  }

  .line-connector-top {
    float: left;
    border-top: #0D2F42 solid 2px;
    width: 220px;
  }
  .line-connector-bottom {
    position: relative;
    border-bottom: #0D2F42 solid 2px;
    height: 50px;
    margin-left: 280px;
  }

  .curve-line {
    float: left;
    height: 50px;
  }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {

  .vr {
    display: none;
  }

  .label {
    color: white;
    font-size: 12px !important;
    font-weight: 600;
    text-transform: uppercase;
  }

  .label-blue {
    color: #0D2F42 !important;
    font-size: 12px !important;
    font-weight: 600;
    text-transform: uppercase;
  }

  .header-wrapper {
    width: 100%;
    height: 60px;
    margin: auto;
    margin-top: 0px;
  }

  .logo {
    float: none;
    margin: auto;
    margin-top: 5px;
  }

  .header-title {
    text-align: center;
    margin-top: 20px;
  }

  .groups-header-wrapper {
    margin-top: 120px;
  }

  .groupsContainer-wrapper {
    height: calc(100% - 250px);
  }

  .footer {
    width: 100%;
    margin: auto;
    margin-left: -15px;
    bottom: 25px;
  }
  
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  .groupsSection-wrapper {
    padding-left: 40px;
  }

  .vr {
    display: block;
  }

  .label {
    color: white;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }

  .footer {
    bottom: 10px;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

  .vr {
    display: block;
  }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  .vr {
    display: block;
  }

}

</style>
