import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/components/Home'
import Login from '@/components/login'
import error404 from '@/components/404'
import Logout from '@/components/logout'
import { msalPluginInstance } from '@/plugins/MSALPlugin'


const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '/404-error',
      name: 'error-404',
      component: error404
    },
    {
      path: '*',
      redirect: '/404-error'
    }
  ]
})

router.beforeEach(async (to, _from, next) => {
  if (to.name == 'Logout' || to.name == 'error-404') {
    next();
  } else if (to.name == 'Login') {
    if(msalPluginInstance.isAuthenticated()) {
      // user logged in - send to main page
      next({name: 'Home'}) 
    } else {
      // user not logged in
      //next();
      await msalPluginInstance.signIn();
    }
  } else {
    if(msalPluginInstance.isAuthenticated()) {
      // user logged in
      // console.log('user logged in')
      next();
    } else {
      // user not logged in - signout which sends to logout page
      // console.log('user not logged in')
      //msalPluginInstance.signOut();
      await msalPluginInstance.signIn();
      next();
    }
  }
});

Vue.use(Router)

export default router
