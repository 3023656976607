// store/index.js
 
import Vue from "vue";
import Vuex from "vuex";
import alerts from './alerts.store';
 
Vue.use(Vuex);
 
export default new Vuex.Store({
 state: {
  token: '',
  extensionsAppId: '',
  extension_dateAdded: '',
  extension_Flagged: '',
  currentDomain: '',
  groupId: '',
  groupName: '',
  groupLogoURL: '',
  appId: '',
  groupNonSigFlag: false,
  currentUserID: '',
  currentUserStatus:'',
  appInsightsConnString:'',
  user: {
    auth : false,
    displayName:'',
    initials:'',
    currentUserEmail:''
  }
 },
 getters: {
    auth: state => {
      return state.user.auth
    }
 },
 // Mutations are the only way to properly change the value of the state object. 
 // An important detail to note is that mutations must be synchronous.
 mutations: {
  setGroupId(state, payload) {
    state.groupId = payload
  },
  setToken(state, payload) {
    state.token = payload
  },
  setGroupName(state, payload) {
    state.groupName = payload
  },
  setGroupLogo(state, payload) {
    state.groupLogoURL = payload
  },
  setAppId(state, payload) {
    state.appId = payload
  },
  setCurrentUserId(state, payload) {
    state.currentUserId = payload
  },
  setCurrentUserStatus (state, payload) {
    state.currentUserStatus = payload
  },
  setUserDisplayName (state, payload) {
    state.user.displayName = payload
  },
  setUserInitials(state, payload) {
    state.user.initials = payload;
  },
  setcurrentUserEmail(state, payload) {
    state.user.currentUserEmail = payload
  },
  changeAuth(state, payload) {
    let isAuth = payload.isAuthenticated();
    state.user.auth = isAuth;
  },
  changeExtensionsApp(state, payload) {
    state.extensionsAppId = payload;
    state.extension_dateAdded = "extension_" + state.extensionsAppId + "_dateAdded";
    state.extension_Flagged = "extension_" + state.extensionsAppId + "_Flagged";
  },
  updateCurrentDomain(state, payload) {
    state.currentDomain = payload
  },
  setAppInsightsConnString(state, payload) {
    state.appInsightsConnString = payload;
  },
  setGroupNonSigFlag(state, payload) {
    state.groupNonSigFlag = payload
  }
 },
 actions: {
    updateExtensionsApp({ commit }) {
      const currentDomain = window.location.origin;
      
      commit('updateCurrentDomain', currentDomain);

      // array of extensions apps Ids per domain(s)
      const extensionsAppOptions = [
        // CHANGE ID TO MATCH LOCAL RUNNING BRANCH
       // Local
       {
         domains: [process.env.VUE_APP_B2C_DOMAIN_LOCAL, process.env.VUE_APP_B2C_DOMAIN_SWA_PROD],
         id: '758392ae23844decaf1d7594e5f043db'
       },
       
       // Dev
       {
         domains: [process.env.VUE_APP_B2C_DOMAIN_DEV,process.env.VUE_APP_B2C_DOMAIN_SWA_DEV_PR, process.env.VUE_APP_B2C_DOMAIN_SWA_DEV],
         id: '758392ae23844decaf1d7594e5f043db'
       },
   
       // Test
       {
         domains: [process.env.VUE_APP_B2C_DOMAIN_TEST, process.env.VUE_APP_B2C_DOMAIN_SWA_TEST],
         id: '2cdd043be83644a49b7efef1a4fc8be6'
       },
   
       // Prod
       {
         domains: [process.env.VUE_APP_B2C_DOMAIN_PROD, process.env.VUE_APP_B2C_DOMAIN_SWA_PROD],
         id: 'a3ba1f5147054d2a94ca8810f1b0c368'
       }
     ]
   
     let extensionsApp = extensionsAppOptions[0]; // default
     commit("changeExtensionsApp", extensionsApp);

      // loop to see which domain matches current domain
      for (let extensionsAppOption of extensionsAppOptions) {
        for (let domain of extensionsAppOption.domains) {
          if (domain.indexOf(currentDomain.toLowerCase()) !== -1) {
            // update extensions app id
            extensionsApp = extensionsAppOption;
            commit("changeExtensionsApp", extensionsApp.id);
          }
        }
      }
    },
    updateAppInsightsConnString({ commit }) {
      const currentDomain = window.location.origin;

      commit('updateCurrentDomain', currentDomain);

      const appInsightsConnStringOptions = [
        // Local
        {
          domains: [process.env.VUE_APP_B2C_DOMAIN_LOCAL],
          connString: process.env.VUE_APP_APP_INSIGHTS_DEV
        },
        // Dev
        {
          domains: [process.env.VUE_APP_B2C_DOMAIN_DEV, process.env.VUE_APP_B2C_DOMAIN_SWA_DEV_PR, process.env.VUE_APP_B2C_DOMAIN_SWA_DEV],
          connString: process.env.VUE_APP_APP_INSIGHTS_DEV
        },
        // Test
        {
          domains: [process.env.VUE_APP_B2C_DOMAIN_TEST, process.env.VUE_APP_B2C_DOMAIN_SWA_TEST],
          connString: process.env.VUE_APP_APP_INSIGHTS_TEST
        },
        // Prod
        {
          domains: [process.env.VUE_APP_B2C_DOMAIN_PROD, process.env.VUE_APP_B2C_DOMAIN_SWA_PROD],
          connString: process.env.VUE_APP_APP_INSIGHTS_PROD
        },
      ]

      let connString = appInsightsConnStringOptions[0].connString; // default
      commit("setAppInsightsConnString", connString);

      // loop to see which domain matches current domain
      for (let appInsightsConnStringOption of appInsightsConnStringOptions) {
        for (let domain of appInsightsConnStringOption.domains) {
          if (domain.indexOf(currentDomain.toLowerCase()) !== -1) {
            connString = appInsightsConnStringOption.connString;
            commit("setAppInsightsConnString", connString);
          }
        }
      }
    }
  },
  modules: {
    alerts,
  },
});