<template>
  <div id="app" style="height: 100vh;">
    <Header />
    <router-view/>
  </div>
</template>

<script>
import Header from './components/Header';
export default {
  name: "App",
  components: {
    Header
  }
};
</script>

<style>
</style>