import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, TooltipPlugin, BTooltip } from 'bootstrap-vue'
import Paginate from 'vuejs-paginate'
import App from './App.vue'
import router from './router'
import store from './store'
import { msalConfig, msalScopes } from './authConfig'
import { MsalPlugin } from './plugins/MSALPlugin'

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/styles/style.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(TooltipPlugin)
Vue.component('b-tooltip', BTooltip)
Vue.component('paginate', Paginate)

const msalOptions = {
  msalConfig: msalConfig,
  msalScopes: msalScopes
};
Vue.use(new MsalPlugin(), msalOptions);

var app = new Vue({ el: '#app', router, store, render: h => h(App) })
