<template>
  <div>
    <div class="alert-modal">
      <div v-on:click="closeModal()" class="modal-close-button">&#10006;</div>
      <b-row class="modal-body-wrapper">
        <slot></slot>
      </b-row>

      <b-row class="modal-btns">
        <slot name="buttons"></slot>
      </b-row>
    </div>
    <div class="modal-overlay"></div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  methods: {
    closeModal: function() {
      this.$emit('close');
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .alert-modal {
    position: absolute;
    background-color: white;
    min-width: 350px;
    max-height: 90%;
    overflow: scroll;
    z-index: 2001;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 20px solid white;
    border-radius: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .modal-close-button {
    position: absolute;
    color: #0D2F42;
    font-size: 1.2em;
    line-height: 0.75;
    top: 0;
    right: 0;
    margin-right: 20px;
    margin-top: 20px;
  }

  .modal-close-button:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  .modal-body-wrapper {
    margin-top: 35px;
  }
  
  .modal-btns {
    display: flex;
    justify-content: center;
    margin: auto;
    height: 35px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .modal-overlay {
    position: absolute;
    background-color: rgb(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 2000;
  }

  ::v-deep .modal-text {
    width: 100%;
    font-size: 16px;
    text-align: center;
  }

  ::v-deep .modal-button {
    font-size: 16px;
    font-weight: 600;
    background-color: #0D2F42;
    border: #0D2F42 2px solid;
    color: white;
    border-radius: 20px;
    padding: 4px 14px;
    transition: 0.20s;
  }

  ::v-deep .modal-button:hover {
    opacity: 0.5;
  }

  ::v-deep .modal-button:disabled {
    opacity: 0.5;
  }

  ::v-deep .modal-button-secondary {
    font-size: 16px;
    font-weight: 600;
    background-color: white;
    border: #0D2F42 2px solid;
    color: #0D2F42;
    border-radius: 20px;
    padding: 4px 14px;
    transition: 0.20s;
  }

  ::v-deep .modal-button-secondary:hover {
    opacity: 0.5;
  }
  
</style>
