<template>
  <Modal @close="close">
    <div class="modal-text">Are you sure you want to remove these members? </div><br>
    <div class="modal-text" v-for="email in membersToDelete" v-bind:key="email">
      <strong>{{ email }}</strong>
    </div>
    <template v-slot:buttons>
      <button class="modal-button-secondary" style="margin-right: 15px;" v-on:click="close()">Cancel</button>
      <button class="modal-button" v-on:click="removeMembers()">Delete</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import axios from 'axios';
import { apiPath } from '../../authConfig';
axios.defaults.baseURL = apiPath;

export default {
  name: "RemoveMembersModal",
  components: {
    Modal,
  },
  props: {
    membersToDelete: Array,
  },
  methods: {
    removeMembers: async function() {
      let users = this.membersToDelete.map(email => {
        return {
          otherMails: [email]
        }
      });
      await axios.delete('/RemoveFromGroup',
      {   
        headers:{
          'Content-Type': 'application/json',
          Authorization: this.$store.state.token
        },
        data: {
          groupId: this.$store.state.groupId,
          users: users
        }
      })
      .then( () => {
        this.$store.dispatch('alerts/openAlert', 'groupMembersRemoved');
        this.$emit('members-removed');

      })
      .catch( () => {
        this.$store.dispatch('alerts/openAlert', 'groupMembersRemovedError');
        this.$emit('members-remove-failed')
      })
    },
    close: function() {
      this.$emit('close');
    },
  },
  
};
</script>
